import { Component, Input, OnInit } from "@angular/core";

interface Guideline {
	appName: string;
	link: string;
}

@Component({
	selector: "app-guideline-button",
	templateUrl: "./guideline-button.component.html",
	styleUrls: ["./guideline-button.component.scss"],
})
export class GuidelineButtonComponent implements OnInit {
	@Input() appName!: string;

	guidelines: Guideline[] = [
		{
			appName: "campaigns-manual",
			link: "https://techrar.com/wp-content/uploads/2025/02/Campagins-Manual.pdf",
		},
	];
	constructor() {}

	ngOnInit(): void {}
	getAppLink() {
		const app = this.guidelines.find((app) => app.appName === this.appName);
		if (app) {
			return app.link;
		} else {
			// todo add request to get the guideline link
			return "https://www.yumealz.com";
		}
	}
}
