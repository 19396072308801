import { Component, Input, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogFormConfig, FormField } from "./dialog-form.model";
import { AppService } from "../../../services/app-service/app-service";
import {
	AvailableAPIs,
	YumealzApiServiceV2,
} from "src/app/shared/services/yumealz-api-service-v2/yumealz-api-v2.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NodeHtmlMarkdown } from "node-html-markdown";
import { Marked } from "marked";

@Component({
	selector: "app-dialog-form",
	templateUrl: "./dialog-form.component.html",
	styleUrls: ["./dialog-form.component.scss"],
})
export class DialogFormComponent implements OnInit {
	@Input() config!: DialogFormConfig;
	form!: FormGroup;
	isSubmitting = false;

	htmlToMarkdownConverter = new NodeHtmlMarkdown();
	markdownToHtmlConverter = new Marked();

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<DialogFormComponent>,
		private appService: AppService,
		private apiService: YumealzApiServiceV2,
		@Inject(MAT_DIALOG_DATA) public data: { config: DialogFormConfig }
	) {
		if (data?.config) {
			this.config = data.config;
		}
	}

	ngOnInit(): void {
		this.initForm();
	}

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: false,
		height: "auto",
		minHeight: "0",
		maxHeight: "auto",
		width: "auto",
		minWidth: "0",
		translate: "yes",
		enableToolbar: true,
		showToolbar: true,
		toolbarHiddenButtons: [
			[
				"strikeThrough",
				"subscript",
				"superscript",
				"justifyLeft",
				"justifyCenter",
				"justifyRight",
				"justifyFull",
				"indent",
				"outdent",
				"fontName",
			],
			[
				"fontSize",
				"textColor",
				"backgroundColor",
				"customClasses",
				"link",
				"unlink",
				"insertImage",
				"insertVideo",
				"insertHorizontalRule",
				"removeFormat",
				"toggleEditorMode",
			],
		],
	};

	private initForm(): void {
		const group: any = {};

		this.config.fields.forEach((field) => {
			const validators = this.getValidators(field);
			let value = field.value;

			// Handle specific field types
			switch (field.type) {
				case "editor":
					value = this.convertMarkdownToHtml(value);
					break;
				case "toggle":
					value = value === undefined ? false : Boolean(value);
					break;
				case "select":
					value = value === undefined || value === null ? "" : value;
					break;
				default:
					value = value || null;
			}

			group[field.name] = [value, validators];
		});

		this.form = this.fb.group(group);
	}

	get formFields() {
		return this.config?.fields || [];
	}

	private getValidators(field: FormField) {
		const validatorsList: any[] = [];

		if (field.required) {
			validatorsList.push(Validators.required);
		}

		field.validators?.forEach((validator) => {
			switch (validator.name) {
				case "email":
					validatorsList.push(Validators.email);
					break;
				case "minLength":
					validatorsList.push(Validators.minLength(validator.value));
					break;
				case "maxLength":
					validatorsList.push(Validators.maxLength(validator.value));
					break;
				case "pattern":
					validatorsList.push(Validators.pattern(validator.value));
					break;
			}
		});

		return validatorsList;
	}

	onSubmit() {
		if (this.form.valid && !this.isSubmitting) {
			try {
				const routes = this.config.routes;
				const version = this.config.version || 1;
				const apiName: AvailableAPIs =
					this.config.apiName || AvailableAPIs.CORE;
				const headers = this.config.headers || [];

				this.isSubmitting = true;
				const formData = { ...this.form.value };

				// Process form values based on field types
				this.config.fields.forEach((field) => {
					const value = formData[field.name];
					switch (field.type) {
						case "date":
							if (value) {
								const date = new Date(value);
								formData[field.name] = date.toLocaleDateString("en-CA");
							}
							break;
						case "select":
							if (value === -1 || value === "") {
								formData[field.name] = null;
							}
							break;
						case "multi-select":
							formData[field.name] = value || [];
							break;
						case "toggle":
							// For toggle fields, ensure it's a boolean but don't validate as required
							formData[field.name] =
								value === undefined ? false : Boolean(value);
							break;
					}
				});

				// Merge with payloadData if it exists
				if (this.config.payloadData) {
					Object.keys(this.config.payloadData).forEach((key) => {
						// Keep form values and only use payloadData for missing fields
						if (
							!formData.hasOwnProperty(key) ||
							formData[key] === null ||
							formData[key] === undefined ||
							formData[key] === ""
						) {
							formData[key] = this.config.payloadData[key];
						}
					});
				}

				if (formData.description_ar) {
					formData.description_ar = this.decodeHtmlEntities(
						formData.description_ar
					);
				}

				const editorField = this.config.fields.find(
					(field) => field.type === "editor"
				);
				if (editorField) {
					// this.form.get(editorField.name)?.setValue(
					//   this.convertHtmlToMarkdown(
					//     this.form.get(editorField.name)?.value
					//   )
					// );
					formData[editorField.name] = this.convertHtmlToMarkdown(
						formData[editorField.name]
					);
				}
				if (this.config.mode === "create") {
					this.apiService
						.postData({ routes, version, apiName, headers: headers }, formData)
						.subscribe({
							next: (res: any) => {
								const successMessage =
									this.config.successMessage || "save-success";
								this.appService.notifySuccess(
									this.appService.translate(successMessage)
								);
								this.dialogRef.close(res);
							},
							error: (error) => {
								console.error("Error saving form:", error);
								this.appService.notifyFailure(
									this.appService.translate("save-error")
								);
								this.isSubmitting = false;
							},
						});
				} else {
					this.apiService
						.updateData(
							{ routes, version, apiName, headers: headers },
							formData
						)

						.subscribe({
							next: (res: any) => {
								const successMessage =
									this.config.successMessage || "save-success";
								this.appService.notifySuccess(
									this.appService.translate(successMessage)
								);
								this.dialogRef.close(res);
							},
							error: (error) => {
								console.error("Error updating form:", error);
								this.appService.notifyFailure(
									this.appService.translate("save-error")
								);
								this.isSubmitting = false;
							},
						});
				}
			} catch (error) {
				console.error("Error in form submission:", error);
				this.appService.notifyFailure(this.appService.translate("save-error"));
				this.isSubmitting = false;
			}
		}
	}

	onCancel() {
		this.dialogRef.close();
	}

	shouldShowField(field: FormField): boolean {
		if (!field.showWhen) return true;
		return this.form.get(field.showWhen.field)?.value === field.showWhen.value;
	}

	getErrorMessage(fieldName: string): string {
		const control = this.form.get(fieldName);
		if (!control || !control.errors) return "";

		const errors = control.errors;
		if (errors["required"]) return "field-required";
		if (errors["email"]) return "invalid-email";
		if (errors["minlength"]) return "min-length-error";
		if (errors["maxlength"]) return "max-length-error";
		if (errors["pattern"]) return "pattern-error";

		return "";
	}

	getMaxLength(field: FormField): number | null {
		return field.validators?.find((v) => v.name === "maxLength")?.value || null;
	}

	getMinLength(field: FormField): number | null {
		return field.validators?.find((v) => v.name === "minLength")?.value || null;
	}

	getPattern(field: FormField): string | null {
		return field.validators?.find((v) => v.name === "pattern")?.value || null;
	}
	convertHtmlToMarkdown(html: string) {
		const markdown = this.htmlToMarkdownConverter.translate(html);
		return markdown;
	}
	convertMarkdownToHtml(markdown: string) {
		const html = this.markdownToHtmlConverter.parse(markdown);
		return html;
	}
	decodeHtmlEntities(text: string): string {
		const doc = new DOMParser().parseFromString(text, "text/html");
		return doc.documentElement.textContent || text;
	}
}
