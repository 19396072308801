import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import {
	IMerchantRegister,
	IRegisterConfirmation,
	User,
} from "src/app/shared/models/classes.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { JwtService } from "../jwt-service/jwt.service";
import {
	Lang,
	LanguageService,
} from "src/app/translate/translation/services/language-service/language.service";

export interface IOtpResponse {
	message: string;
	waiting_seconds: number;
	_timestamp: string;
}

export enum IUserAccessType {
	MP_YUMEALZ = "mp_yumealz",
	MP_PROVIDER = "mp_provider",
	SaaS = "saas",
	Both = "both",
}

@Injectable({
	providedIn: "root",
})
export class AuthService {
	// todo fix this
	private userSubject: BehaviorSubject<User | null>;
	public user$: Observable<User | null>;

	// todo make the value of home component based on the provided permission and remove the rerouting logic from the access guard
	readonly homeComponent = "home";
	readonly dashboardComponent = "dashboard";
	private otpEndPoint = environment.newApiUrl + "v2/auth/request-otp/";
	private accessV2EndPoint =
		environment.newApiUrl + "v2/auth/dashboard-access/";
	private verifyOtpEndPoint = environment.newApiUrl + "v2/auth/otp-check/";
	private changePasswordEndPoint =
		environment.newApiUrl + "v2/auth/change-password/";
	private accesTokenEndPoint = environment.apiUrl + "auth/dashboard-access/";
	private refreshTokenEndPoint =
		environment.newApiUrl + "v2/auth/dashboard-refresh/";
	private merchantRegisterationsEndPoint =
		environment.utilityApiUrl + "merchant/registerations/";
	private registerMetaEndPoint = environment.publicApiUrl + "v1/register/meta/";
	private preCheckEndPoint =
		environment.publicApiUrl + "v1/register/pre-check/";
	private registerEndPoint = environment.publicApiUrl + "v1/register/";
	private registerConfirmationEndPoint =
		environment.publicApiUrl + "v1/register-confirmation/";
	private brandFilesEndPoint = environment.utilityApiUrl + "brand-files";
	private profileEndPoint = environment.newApiUrl + "v1/dashboard/me/";

	appLanguage = this.languageService.getCurrentLang();
	constructor(
		private router: Router,
		private http: HttpClient,
		private snackBar: MatSnackBar,
		private jwtService: JwtService,
		private languageService: LanguageService
	) {
		//todo: change this to use token
		this.userSubject = new BehaviorSubject<User | null>(null);
		this.user$ = this.userSubject.asObservable();

		const redirectRoute = localStorage.getItem("redirectAfterLogin");
		if (redirectRoute) {
			localStorage.removeItem("redirectAfterLogin");
			this.router.navigate([redirectRoute]);
		}
	}

	public get userValue(): User {
		if (this.userSubject.value) {
			return this.userSubject.value;
		} else {
			// todo fix this
			return {
				username: "dummy user",
			};
		}
	}
	logIn(loginInfo: {
		username?: string;
		email?: string;
		password: string;
	}): Observable<User> {
		return this.http
			.post<User>(`${this.accessV2EndPoint}`, {
				...loginInfo,
			})
			.pipe(
				map((response) => {
					const user = this.jwtService.storeTokens(response);
					this.userSubject.next(user);
					const loggedInMessage =
						this.appLanguage == Lang.english
							? "Logged In Successfully"
							: "تم تسجيل الدخول بنجاح";
					this.alert(loggedInMessage);
					this.http.get<any>(`${this.profileEndPoint}`).subscribe(
						(profile) => {
							if (profile.is_email_verified) {
								window.location.href = "/" + this.dashboardComponent;
							} else {
								window.location.href = "/" + this.homeComponent;
							}
						},
						(error) => {
							window.location.href = "/" + this.homeComponent;
						}
					);
					return user;
				}),
				catchError((e) => {
					console.log(e);
					return throwError(() => e);
				})
			);
	}
	requestOtp(otpRequestInfo: {
		username?: string;
		mobile_number?: string;
		email?: string;
		action?: string;
	}): Observable<IOtpResponse> {
		return this.http.post<IOtpResponse>(`${this.otpEndPoint}`, {
			...otpRequestInfo,
			client: "dashboard",
		});
	}
	loginWithOtp(loginInfo: { otp: string; username?: string; email?: string }) {
		return this.http
			.post<User>(`${this.accessV2EndPoint}`, {
				...loginInfo,
			})
			.pipe(
				map((response) => {
					const user = this.jwtService.storeTokens(response);
					this.userSubject.next(user);
					const loggedInMessage =
						this.appLanguage == Lang.english
							? "Logged In Successfully"
							: "تم تسجيل الدخول بنجاح";
					this.alert(loggedInMessage);
					this.http.get<any>(`${this.profileEndPoint}`).subscribe(
						(profile) => {
							if (profile.is_email_verified) {
								window.location.href = "/" + this.dashboardComponent;
							} else {
								window.location.href = "/" + this.homeComponent;
							}
						},
						(error) => {
							window.location.href = "/" + this.homeComponent;
						}
					);
					return user;
				}),
				catchError((e) => {
					console.log(e);
					return throwError(() => e);
				})
			);
	}

	verifyOtp(otpVerifyInfo: {
		otp: string;
		mobile_number?: string;
		email?: string;
	}) {
		return this.http.post<any>(`${this.verifyOtpEndPoint}`, {
			...otpVerifyInfo,
		});
	}

	changePassword(changePasswordInfo: {
		new_password: string;
		email?: string;
		mobile_number?: string;
	}) {
		return this.http.post<any>(`${this.changePasswordEndPoint}`, {
			...changePasswordInfo,
		});
	}

	refreshToken() {
		const refreshToken = this.jwtService.getRefreshToken();
		if (!refreshToken) {
			this.logOut();
		}

		return this.http.post(`${this.refreshTokenEndPoint}`, {
			refresh: refreshToken,
		});
	}
	merchantRegisterations(merchantRegisterationsInfo: IMerchantRegister) {
		return this.http.post<any>(`${this.merchantRegisterationsEndPoint}`, {
			...merchantRegisterationsInfo,
		});
	}
	getRegisterMeta(): Observable<any> {
		return this.http.get<any>(this.registerMetaEndPoint);
	}
	preCheck(merchantRegisterationsInfo: IMerchantRegister) {
		return this.http.post<any>(`${this.preCheckEndPoint}`, {
			...merchantRegisterationsInfo,
		});
	}
	register(registerInfo: IRegisterConfirmation) {
		return this.http.post<any>(`${this.registerEndPoint}`, {
			...registerInfo,
		});
	}
	registerConfirmation(registerConfirmationInfo: IRegisterConfirmation) {
		return this.http.post<any>(`${this.registerConfirmationEndPoint}`, {
			...registerConfirmationInfo,
		});
	}
	logOut() {
		//todo : change this to delete refresh token from auth server
		this.jwtService.clearTokens();
		this.userSubject.next(null);
		const loggedOutMessage =
			this.appLanguage == Lang.english
				? "Logged Out Successfully"
				: "تم تسجيل الخروج بنجاح";
		this.alert(loggedOutMessage);
		this.navigateToLogin();
	}

	changeOrganization(newOrgId: number) {
		const refreshToken = this.jwtService.getRefreshToken();
		if (!refreshToken) {
			this.logOut();
		}

		this.http
			.post(`${environment.newApiUrl}v1/dashboard/change-org/`, {
				refresh: refreshToken,
				new_org_id: newOrgId,
			})
			.subscribe((response) => {
				this.removeOldOrgLocalStorageItem();
				const user = this.jwtService.storeTokens(response);
				this.userSubject.next(user);
				location.reload();
			});
	}

	removeOldOrgLocalStorageItem() {}
	getLoggedInUserId(): number {
		const decodedToken = this.jwtService.getDecodedAccessToken();
		return decodedToken.user_id;
	}
	getUserAccessType(): IUserAccessType {
		const decodedToken = this.jwtService.getDecodedAccessToken();
		return decodedToken?.access_type;
	}
	isLoggedIn(): boolean {
		return this.jwtService.isAccessTokenValid();
	}
	isRefreshTokenValid(): boolean {
		return this.jwtService.isRefreshTokenValid();
	}
	isYumealzUser(): boolean {
		return this.getUserAccessType() === IUserAccessType.MP_YUMEALZ;
	}
	hasSaaS(): boolean {
		return (
			this.getUserAccessType() === IUserAccessType.SaaS ||
			this.getUserAccessType() === IUserAccessType.Both
		);
	}
	getRestaurantIds(): number[] {
		return (this.jwtService.getDecodedAccessToken().app_ids as string)
			.split(",")
			.map((value) => Number(value));
	}

	getOrgId(): number {
		return this.jwtService.getDecodedAccessToken()?.org_id;
	}

	// redirect functions
	navigateToHome() {
		this.router.navigate([this.homeComponent]);
	}

	navigateToLogin() {
		this.router.navigate(["login"]);
	}

	// other functions
	alert(message: string) {
		this.snackBar.open(message, "Ok", {
			horizontalPosition: "center",
			verticalPosition: "bottom",
			duration: 300,
		});
	}

	loginAfterRegister(tokens: any) {
		const user = this.jwtService.storeTokens(tokens);
		this.userSubject.next(user);
		localStorage.removeItem("tourCompleted");
		localStorage.removeItem("setupCompleted");
		const loggedInMessage =
			this.appLanguage == Lang.english
				? "Account Created Successfully"
				: "تم إنشاء الحساب بنجاح";
		this.alert(loggedInMessage);
		window.location.href = "/" + this.homeComponent;
		return user;
	}
	requestOtpVerifyEmail(email: string): Observable<IOtpResponse> {
		return this.http.post<IOtpResponse>(
			`${environment.newApiUrl}v2/auth/request-otp/?client=dashboard`,
			{
				email: email,
				action: "verify_email",
			}
		);
	}
	verifyEmail(
		otp: string,
		email: string,
		brand_name: string,
		appId: string
	): Observable<any> {
		return this.http.post<any>(
			`${environment.newApiUrl}v1/dashboard/verify-email/`,
			{
				otp: otp,
				email: email,
				brand_name: brand_name,
			},
			{
				headers: {
					"app-id": `${appId}`,
				},
			}
		);
	}
	getBrandFiles(merchant_name_id: string): Observable<any> {
		return this.http.get<any>(
			`${this.brandFilesEndPoint}?merchant_name_id=${merchant_name_id}&is_preview=true&folder_name=mf_docs`
		);
	}
}
