import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "src/app/translate/translate.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LoadingComponent } from "./components/loading/loading.component";
import { BooleanIconComponent } from "./components/boolean-icon/boolean-icon.component";
import { ConfirmActionDialogComponent } from "./components/confirm-action-dialog/confirm-action-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ConfirmActionWithBooleanComponent } from "./components/confirm-action-with-boolean/confirm-action-with-boolean.component";
import { GuidelineButtonComponent } from "./components/guideline-button/guideline-button.component";
import { BooleanBadgeComponent } from "./components/boolean-badge/boolean-badge.component";
import { SelectDeliveryWindowComponent } from "./components/select-delivery-window/select-delivery-window.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { AlertPopupComponent } from "./components/alertpopup/alertpopup.component";
import { HideElementDirective } from "../directives/hide-element/hide-element.directive";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TimeFormatConverterModule } from "src/app/shared/pipes/time-format-converter/time-format-converter.module";
import { ViewImageDialogComponent } from "./components/view-image-dialog/view-image-dialog.component";
import { DialogFormComponent } from "./components/dialog-form/dialog-form.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { AppCurrencyDirective } from "../directives/app-currency.directive";
import { AngularEditorModule } from "@kolkov/angular-editor";

const importedSharedModules = [
	MatProgressBarModule,
	MatButtonModule,
	MatRadioModule,
	MatIconModule,
	ReactiveFormsModule,
	FormsModule,
	MatFormFieldModule,
	MatInputModule,
	MatSlideToggleModule,
	MatOptionModule,
	MatSelectModule,
	MatCardModule,
	MatTooltipModule,
	// todo remove this
	// These are added for the dialog components that use datepicker
	MatNativeDateModule,
	MatDatepickerModule,
	MatDialogModule,
	AngularEditorModule,
];

const localSharedModules = [
	TranslateModule,
	MatProgressSpinnerModule,
	TimeFormatConverterModule,
];
const localSharedComponents = [
	// todo i might need to remove the local shared components and make them as modules and import each module in the module from where i want it
	SelectDeliveryWindowComponent,
	BooleanBadgeComponent,
	BooleanIconComponent,
	ConfirmActionDialogComponent,
	ConfirmActionWithBooleanComponent,
	GuidelineButtonComponent,
	LoadingComponent,
	SearchBarComponent,
	AlertPopupComponent,
	DialogFormComponent,
];
const localSharedDirectives = [HideElementDirective, AppCurrencyDirective];

const allModules = [...importedSharedModules, ...localSharedModules];
@NgModule({
	declarations: [
		...localSharedComponents,
		...localSharedDirectives,
		ViewImageDialogComponent,
	],
	imports: [CommonModule, allModules],
	exports: [allModules, ...localSharedComponents, ...localSharedDirectives],
})
export class ReusableComponentsModule {}
