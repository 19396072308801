import { Injectable } from "@angular/core";
import {
	YumealzApiServiceV2,
	AvailableAPIs,
} from "src/app/shared/services/yumealz-api-service-v2/yumealz-api-v2.service";
import {
	BehaviorSubject,
	Observable,
	switchMap,
	map,
	forkJoin,
	of,
	catchError,
	take,
} from "rxjs";
import {
	HttpClient,
	HttpContext,
	HttpContextToken,
} from "@angular/common/http";
import { getDecodedToken } from "src/app/shared/utils/general-utils";
// Import local translation files as fallback
import * as localEn from "../../translations/en.json";
import * as localAr from "../../translations/ar.json";
import * as localGymEn from "../../translations/gym_en.json";
import * as localGymAr from "../../translations/gym_ar.json";

export const SKIP_INTERCEPTOR_FOR_TRANSLATION = new HttpContextToken(() => false);

interface TranslationFile {
	name: string;
	url: string;
}

interface TranslationsResponse {
	message: string;
	translations: TranslationFile[];
}

interface TranslationData {
	[key: string]: string;
}

interface Translations {
	en: TranslationData;
	ar: TranslationData;
	gym_en: TranslationData;
	gym_ar: TranslationData;
}

interface ConfigResponse {
	key: string;
	value: string;
	description: string;
	tag: string;
}

@Injectable({
	providedIn: "root",
})
export class TranslationLoaderService {
	private translations = new BehaviorSubject<Translations | null>(null);
	translations$ = this.translations.asObservable();
	private translationUrls: { [key: string]: string } = {};

	// Local translations fallback
	private localTranslations: Translations = {
		en: localEn,
		ar: localAr,
		gym_en: localGymEn,
		gym_ar: localGymAr,
	};

	constructor(
		private yumealzApiServiceV2: YumealzApiServiceV2,
		private http: HttpClient
	) {}

	private checkTranslationVersion(): Observable<boolean> {
		return this.yumealzApiServiceV2
			.getData<ConfigResponse[]>({
				apiName: AvailableAPIs.CORE,
				routes: ["utilities", "configs"],
				embed: [],
			})
			.pipe(
				take(1),
				map((configs) => {
					const versionConfig = configs.find(
						(config) => config.key === "TRANSLATION_VERSION"
					);
					if (!versionConfig) return true;

					const storedVersion = localStorage.getItem("translationVersion");
					const newVersion = versionConfig.value;

					if (storedVersion !== newVersion) {
						localStorage.setItem("translationVersion", newVersion);
						return true;
					}

					return false;
				}),
				catchError(() => of(true))
			);
	}

	private getAllTranslationFiles(
		translations: TranslationFile[]
	): TranslationFile[] {
		translations.forEach((file) => {
			this.translationUrls[file.name] = file.url;
		});

		// Return all translation files
		return translations;
	}

	private getLocalTranslation(
		lang: string,
		isGym: boolean = false
	): TranslationData {
		if (isGym) {
			return lang === "en"
				? this.localTranslations.gym_en
				: this.localTranslations.gym_ar;
		}
		return lang === "en"
			? this.localTranslations.en
			: this.localTranslations.ar;
	}

	loadTranslations(): Observable<Translations> {
		// Check if we have translations in memory
		const currentTranslations = this.translations.getValue();
		if (currentTranslations) {
			return of(currentTranslations).pipe(take(1));
		}

		return this.checkTranslationVersion().pipe(
			take(1),
			switchMap((needsUpdate) => {
				if (!needsUpdate) {
					// Try to load from localStorage
					const cachedTranslations = localStorage.getItem("translations");
					if (cachedTranslations) {
						const parsed = JSON.parse(cachedTranslations);
						this.translations.next(parsed);
						return of(parsed);
					}
				}

				// If we need update or no cache, load from API
				return this.loadTranslationsFromApi();
			})
		);
	}

	private loadTranslationsFromApi(): Observable<Translations> {
		return this.yumealzApiServiceV2
			.getData<TranslationsResponse>({
				apiName: AvailableAPIs.UTILITY,
				routes: ["translations"],
			})
			.pipe(
				take(1),
				switchMap((response) => this.processTranslationResponse(response)),
				catchError(() => this.handleApiError())
			);
	}

	private processTranslationResponse(response: TranslationsResponse): Observable<Translations> {
		if (!response.translations?.length) {
			return this.handleEmptyTranslations();
		}

		const translationRequests = this.createTranslationRequests(response.translations);
		return forkJoin(translationRequests).pipe(
			take(1),
			map((translationFiles) => this.buildTranslationsObject(response.translations, translationFiles))
		);
	}

	private handleEmptyTranslations(): Observable<Translations> {
		localStorage.removeItem("translations");
		localStorage.removeItem("translationVersion");
		const emptyTranslations: Translations = {
			en: {},
			ar: {},
			gym_en: {},
			gym_ar: {},
		};
		this.translations.next(emptyTranslations);
		return of(emptyTranslations).pipe(take(1));
	}

	private createTranslationRequests(files: TranslationFile[]): Observable<TranslationData>[] {
		this.getAllTranslationFiles(files);
		return files.map((file) =>
			this.http
				.get<TranslationData>(file.url, {
					headers: { Accept: "application/json" },
					context: new HttpContext().set(SKIP_INTERCEPTOR_FOR_TRANSLATION, true),
				})
				.pipe(
					take(1),
					catchError(() => {
						const isGymFile = file.name.startsWith("gym_");
						const lang = file.name.includes("en.json") ? "en" : "ar";
						return of(this.getLocalTranslation(lang, isGymFile)).pipe(take(1));
					})
				)
		);
	}

	private buildTranslationsObject(files: TranslationFile[], translationFiles: TranslationData[]): Translations {
		const translations: Translations = {
			en: {},
			ar: {},
			gym_en: {},
			gym_ar: {},
		};

		files.forEach((file, index) => {
			const key = file.name.replace(".json", "") as keyof Translations;
			translations[key] = translationFiles[index];
		});

		localStorage.setItem("translations", JSON.stringify(translations));
		this.translations.next(translations);
		return translations;
	}

	private handleApiError(): Observable<Translations> {
		const translations: Translations = { ...this.localTranslations };
		this.translations.next(translations);
		return of(translations).pipe(take(1));
	}

	getTranslations() {
		return this.translations.getValue();
	}

	loadLanguage(lang: string): Observable<TranslationData> {
		const currentTranslations = this.translations.getValue();
		const isGym = getDecodedToken()?.obtype === "gym";

		if (!currentTranslations) {
			return this.loadTranslations().pipe(
				take(1),
				map((translations) => {
					const key = isGym ? `gym_${lang}` : lang;
					return translations[key as keyof Translations];
				})
			);
		}

		const key = isGym ? `gym_${lang}` : lang;
		return of(currentTranslations[key as keyof Translations]).pipe(take(1));
	}

	resetTranslations(): void {
		localStorage.removeItem("translations");
		localStorage.removeItem("translationVersion");
		this.translations.next(null);
	}
}
