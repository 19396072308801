import { Injectable } from "@angular/core";
import { IAllergy } from "./models/classes.model";
import { BehaviorSubject } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class RestaurantService {
	private restaurantIdSubject = new BehaviorSubject<number | null>(
		this.getStoredRestaurantId()
	);
	restaurantId$ = this.restaurantIdSubject.asObservable();

	constructor() {}

	get allergies(): IAllergy[] {
		return [
			{ name: "peanuts", emoji: "🥜" },
			{ name: "nuts", emoji: "🌰" },
			{ name: "milk", emoji: "🥛" },
			{ name: "eggs", emoji: "🥚" },
			{ name: "wheat", emoji: "🌿" },
			{ name: "soy", emoji: "🫘" },
			{ name: "fish", emoji: "🐟" },
			{ name: "shellfish", emoji: "🦐" },
			{ name: "gluten", emoji: "🌾" },
			{ name: "garlic", emoji: "🧄" },
			{ name: "spicy", emoji: "🌶️" },
			{ name: "sesame", emoji: "🫙" },
		];
	}
	getAllergyEmoji(allergyName: string) {
		return (
			this.allergies.find((allergy) => allergy.name === allergyName)?.emoji ||
			"❗️"
		);
	}
	setRestaurantId(id: number) {
		this.restaurantIdSubject.next(id);
		localStorage.setItem("selectedRestaurantId", id.toString());
	}

	private getStoredRestaurantId(): number | null {
		const storedId = localStorage.getItem("selectedRestaurantId");
		return storedId ? parseInt(storedId, 10) : null;
	}
}
