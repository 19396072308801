import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "src/app/shared/services/app-service/app-service";

@Component({
	selector: "app-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
	appLanguage = this.appService.getCurrentLanguage();

	@Input() searchText: string = "";
	@Input() placeHolder: string = this.appLanguage === "en" ? "Search" : "بحث";
	@Input() showSearchIcon: boolean = false;
	@Input() minSearchTextLength: number = 0;
	@Output() searchEvent = new EventEmitter<string>();

	showSearchTextLimit: boolean = false;

	constructor(public appService: AppService) {}

	ngOnInit(): void {}
	keyUp(event: KeyboardEvent) {
		if (event.key === "Enter") {
			if (this.debounceTimeout) {
				clearTimeout(this.debounceTimeout);
				this.debounceTimeout = null;
			}
			if (this.searchText.trim().length >= this.minSearchTextLength) {
				this.showSearchTextLimit = false;
				this.searchEvent.emit(this.searchText.trim());
			} else {
				this.showSearchTextLimit = true;
			}
		} else if (this.searchText.trim().length > this.minSearchTextLength) {
			this.showSearchTextLimit = false;
			this.fireChangeEvent(event);
		}
	}
	private debounceTimeout: any;

	fireChangeEvent(event: any) {
		event.stopPropagation();
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
		if (this.searchText.trim().length < this.minSearchTextLength) {
			this.showSearchTextLimit = true;
			return;
		}
		this.showSearchTextLimit = false;
		this.debounceTimeout = setTimeout(() => {
			this.searchEvent.emit(this.searchText.trim());
			this.debounceTimeout = null;
		}, 1000);
	}

	clearText() {
		this.searchText = "";
		this.fireChangeEvent({ stopPropagation: () => {} });
		this.searchEvent.emit("");
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
	}
}
