import { ConnectivityService } from "./../../../services/connectivity-service/connectivity.service";
import { AfterViewInit, HostListener, OnDestroy } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Subscription, filter, take } from "rxjs";
import {
	IAppConfig,
	IDashboardUserProfile,
	Restaurant,
} from "src/app/shared/models/classes.model";
import { AppService } from "src/app/shared/services/app-service/app-service";
import { AuthService } from "src/app/shared/services/auth-service/auth.service";
import {
	Lang,
	LanguageService,
} from "src/app/translate/translation/services/language-service/language.service";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";
import * as version from "src/environments/version.json";
import { NavigationEnd, Router } from "@angular/router";
import { YumealzApiServiceV2 } from "src/app/shared/services/yumealz-api-service-v2/yumealz-api-v2.service";
import { ConfigService } from "src/app/shared/services/config-service/config-service.service";
import { RestaurantService } from "src/app/restaurants/restaurant.service";

interface IFeatureItem {
	text: string;
	icon: string;
	route?: string;
	fullRoute?: string[];
	permissionCode: string;
	hasUpdates?: boolean;
	numberOfUpdates?: number;
	// to soft launch feature in production
	isTechrarOnlyInProduction?: boolean;
	isTechrarOnly?: boolean;
}
interface IFeatureFolder {
	text: string;
	icon: string;
	route: string;
	permissionCode: string;
	hasUpdates?: boolean;
	numberOfUpdates?: number;
	isOpen?: boolean;
	features: IFeatureItem[];
}

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
	readonly gymPackagesTabTitle = "packages";
	appVersion = version;
	featuresList: IFeatureItem[] = [
		{
			text: "home",
			icon: "bxs-home",
			route: "home",
			permissionCode: "tab-restaurants", //TODO add permission,
			// isTechrarOnlyInProduction: true,
		},
		{
			text: "dashboard",
			icon: "bxs-dashboard",
			route: "dashboard",
			permissionCode: "tab-dashboard",
		},

		// {
		// 	text: "invoices",
		// 	icon: "bx-file",
		// 	route: "invoices",
		// 	permissionCode: "tab-invoices",
		// },
	];
	belowFeaturesList: IFeatureItem[] = [
		{
			text: "help-desk",
			icon: "bx-spreadsheet",
			route: "help-desk",
			permissionCode: "tab-restaurants",
		},
		{
			text: "analytics",
			icon: "bx-bar-chart-alt-2",
			route: "analytics",
			permissionCode: "page-quicksight-report",
		},
		{
			text: "billing",
			icon: "bx-credit-card",
			route: "billing",
			permissionCode: "endpoint-list-restaurants",
		},
		{
			text: "your-plan",
			icon: "bx-package",
			route: "your-plan",
			permissionCode: "endpoint-list-restaurants",
		},
	];

	featuresFoldersList: IFeatureFolder[] = [
		{
			text: "sector-agnostic",
			icon: "bx-layer",
			route: "token",
			permissionCode: "tab-sector-agnostic",
			features: [
				{
					text: "items",
					icon: "bx-category",
					fullRoute: ["token", "items"],
					permissionCode: "tab-items",
				},
				{
					text: "categories",
					icon: "bx-package",
					fullRoute: ["token", "item-categories"],
					permissionCode: "tab-item-category",
				},
				{
					text: "orders",
					icon: "bx-receipt",
					fullRoute: ["token", "orders"],
					permissionCode: "tab-sector-agnostic-orders",
				},
				{
					text: "invoices",
					icon: "bx-file",
					fullRoute: ["token", "invoices"],
					permissionCode: "tab-sector-agnostic-invoices",
				},
				{
					text: "transactions",
					icon: "bx-transfer",
					fullRoute: ["token", "transactions"],
					permissionCode: "tab-sector-agnostic-transactions",
				},
				{
					text: "dispatch-orders",
					icon: "bx-car",
					fullRoute: ["token", "dispatch-orders"],
					permissionCode: "tab-sector-agnostic-dispatch-orders",
				},
				{
					text: "digital-passes",
					icon: "bx-id-card",
					fullRoute: ["token", "digital-passes"],
					permissionCode: "tab-sector-agnostic-digital-passes",
				},
				{
					text: "subscriptions",
					icon: "bx-list-ul",
					fullRoute: ["your-subscriptions"],
					permissionCode: "tab-items",
				},
			],
		},
		{
			text: "subscriptions",
			icon: "bx-detail",
			route: "subscriptions",
			permissionCode: "folder-subscriptions",
			features: [
				{
					text: "subscriptions",
					icon: "bx-detail",
					fullRoute: ["subscriptions"],
					permissionCode: "tab-subscriptions",
				},
				{
					text: "complaints",
					icon: "bx-message-square-x",
					fullRoute: ["complaints"],
					permissionCode: "tab-complaints",
				},
				{
					text: "sub-orders",
					icon: "bx-package",
					fullRoute: ["sub-orders"],
					permissionCode: "tab-sub-orders",
				},
				{
					text: "sub-orders-2",
					icon: "bx-dumbbell",
					fullRoute: ["suborders-v2"],
					permissionCode: "tab-sub-orders",
				},
				{
					text: "suborder-statuses",
					icon: "bx-list-check",
					fullRoute: ["suborder-statuses"],
					permissionCode: "page-suborder-statuses",
				},
				{
					text: "customers-profiles",
					icon: "bx-user",
					fullRoute: ["customers", "all"],
					permissionCode: "tab-customers",
				},
				{
					text: "orders",
					icon: "bx-receipt",
					fullRoute: ["orders"],
					permissionCode: "tab-orders",
				},
			],
		},
		{
			text: "menus",
			icon: "bx-menu",
			route: "menus",
			permissionCode: "tab-folder-menu",
			features: [
				{
					text: "sizes",
					icon: "bx-restaurant",
					fullRoute: ["sizes"],
					permissionCode: "page-sizes",
				},
				{
					text: "products",
					icon: "bx-restaurant",
					fullRoute: ["products"],
					permissionCode: "page-products",
				},

				{
					text: "plan-tags",
					icon: "bx-purchase-tag",
					route: "plan-tags",
					permissionCode: "tab-plan-tags",
				},
				{
					text: "menus",
					icon: "bx-menu",
					route: "all",
					permissionCode: "page-menu",
				},
				{
					text: "menu-plans",
					icon: "bx-calendar",
					route: "plans",
					permissionCode: "page-menu-plans",
				},
			],
		},
		// for gym
		{
			text: this.gymPackagesTabTitle,
			icon: "bx-menu",
			route: "menus",
			permissionCode: "tab-folder-menu",
			features: [
				{
					text: "products",
					icon: "bx-restaurant",
					fullRoute: ["products"],
					permissionCode: "page-products",
				},
				{
					text: "plan-tags",
					icon: "bx-purchase-tag",
					route: "plan-tags",
					permissionCode: "tab-plan-tags",
				},
				{
					text: "plans",
					icon: "bx-calendar",
					route: "plans",
					permissionCode: "page-menu-plans",
				},
			],
		},
		{
			text: "classes",
			icon: "bx-run",
			route: "classes",
			permissionCode: "tab-folder-menu",
			features: [
				{
					text: "trainers",
					icon: "bx-user",
					route: "trainers",
					permissionCode: "page-trainers",
				},
				{
					text: "zones",
					icon: "bx-map",
					route: "zones",
					permissionCode: "page-zones",
				},
				{
					text: "categories",
					icon: "bx-category",
					route: "categories",
					permissionCode: "page-categories",
				},
				{
					text: "classes",
					icon: "bx-table",
					route: "appointments",
					permissionCode: "page-classes",
				},
			],
		},
		{
			text: "marketing",
			icon: "bx-world",
			route: "marketing",
			permissionCode: "tab-marketing",
			features: [
				{
					text: "abandoned-carts",
					icon: "bx-cart-alt",
					fullRoute: ["marketing", "abandoned-carts"],
					permissionCode: "tab-orders",
				},
				{
					text: "vouchers",
					icon: "bx-extension",
					fullRoute: ["marketing", "vouchers", "all"],
					permissionCode: "tab-vouchers",
				},
				{
					text: "notification",
					icon: "bx-bell",
					fullRoute: ["campaigns"],
					permissionCode: "tab-campaigns",
				},
				{
					text: "ratings",
					icon: "bx-star",
					fullRoute: ["ratings"],
					permissionCode: "tab-ratings",
				},
				{
					text: "plan-tags",
					icon: "bx-purchase-tag",
					fullRoute: ["menus", "plan-tags"],
					permissionCode: "tab-plan-tags",
				},
				{
					text: "map-tool-for-vouchers",
					icon: "bx-map-alt",
					fullRoute: ["marketing", "map-tool"],
					permissionCode: "tab-map-tool",
				},
				{
					text: "corporates",
					icon: "bx-buildings",
					fullRoute: ["marketing", "corporates", "all"],
					permissionCode: "tab-corporates",
				},
				{
					text: "corporate-locations",
					icon: "bx-current-location",
					fullRoute: ["marketing", "corporates", "corporate-locations"],
					permissionCode: "tab-corporate-locations",
				},
			],
		},
		{
			text: "store",
			icon: "bx-store",
			route: "store",
			permissionCode: "tab-folder-store",
			features: [
				{
					text: "products",
					icon: "bx-restaurant",
					fullRoute: ["products"],
					permissionCode: "page-products",
				},
				{
					text: "inventory",
					icon: "bx-box",
					fullRoute: ["inventory"],
					permissionCode: "tab-inventory",
				},

				{
					text: "store-orders",
					icon: "bx-receipt",
					fullRoute: ["on-demand-orders"],
					permissionCode: "tab-on-demand-orders",
				},
				{
					text: "store-orders-map",
					icon: "bx-map-pin",
					fullRoute: ["delivery", "on-demand-orders-map"],
					permissionCode: "tab-store-orders-map",
				},
				{
					text: "store-boq",
					icon: "bx-spreadsheet",
					fullRoute: ["reports", "store-billOfQty"],
					permissionCode: "tab-store-boq-report",
				},
				{
					text: "store-packaging",
					icon: "bx-package",
					fullRoute: ["reports", "store-packaging"],
					permissionCode: "tab-store-packaging-report",
				},
			],
		},
		{
			text: "delivery",
			icon: "bx-car",
			route: "delivery",
			permissionCode: "tab-folder-delivery",
			features: [
				{
					text: "sub-orders-map",
					icon: "bx-map-pin",
					route: "sub-orders-map",
					permissionCode: "tab-sub-orders-map",
				},
				// here I removed on-demand orders map
				{
					text: "captains",
					icon: "bx-id-card",
					route: "captains",
					permissionCode: "tab-captains",
				},
				{
					text: "delivery-windows",
					icon: "bx-time",
					route: "delivery-windows",
					permissionCode: "tab-delivery-windows",
				},
				{
					text: "zones",
					icon: "bx-sitemap",
					route: "zones",
					permissionCode: "tab-zones",
				},
				{
					text: "district-groups",
					icon: "bx-sitemap",
					route: "district-groups",
					permissionCode: "tab-district-groups",
				},
				{
					text: "open-sub-orders-summary",
					icon: "bx-doughnut-chart",
					route: "open-suborders-summary",
					permissionCode: "tab-sub-orders",
				},
				{
					text: "branches",
					icon: "bx-sitemap",
					fullRoute: ["branches"],
					permissionCode: "element-restaurant-branches",
				},
			],
		},
		{
			text: "operation",
			icon: "bx-building",
			route: "delivery",
			permissionCode: "tab-folder-delivery",
			features: [
				{
					text: "captains",
					icon: "bx-id-card",
					route: "captains",
					permissionCode: "tab-captains",
				},

				{
					text: "branches",
					icon: "bx-sitemap",
					fullRoute: ["branches"],
					permissionCode: "element-restaurant-branches",
				},
			],
		},
		{
			text: "operation-reports",
			icon: "bxs-report",
			route: "reports",
			permissionCode: "tab-folder-reports",
			features: [
				{
					text: "bill-of-quantity",
					icon: "bx-spreadsheet",
					route: "sub-billOfQty",
					permissionCode: "tab-boq-report",
				},
				{
					text: "packaging",
					icon: "bx-package",
					route: "sub-packaging",
					permissionCode: "tab-packaging-report",
				},
				{
					text: "delivery-report",
					icon: "bx-receipt",
					route: "delivery-report",
					permissionCode: "tab-sub-orders",
				},
				{
					text: "refund-report",
					icon: "bx-receipt",
					route: "refund-report",
					permissionCode: "tab-refund",
				},
				{
					text: "plans-prices",
					icon: "bx-money",
					fullRoute: ["reports", "plans-prices"],
					permissionCode: "tab-plan-prices",
				},
				{
					text: "saas-financial",
					icon: "bx-money",
					route: "subscriptions",
					permissionCode: "tab-saas-financial-report",
				},
				{
					text: "grouped-sub-orders-financial",
					icon: "bx-money",
					route: "grouped-sub-orders-financial",
					permissionCode: "page-grouped-sub-orders-financial",
				},
			],
		},
		{
			text: "reports",
			icon: "bxs-report",
			route: "reports",
			permissionCode: "tab-folder-reports",
			features: [
				{
					text: "plans-prices",
					icon: "bx-money",
					fullRoute: ["reports", "plans-prices"],
					permissionCode: "tab-plan-prices",
				},
				{
					text: "saas-financial",
					icon: "bx-money",
					route: "subscriptions",
					permissionCode: "tab-saas-financial-report",
				},
			],
		},
		{
			text: "my-fatoorah",
			icon: "bx-money",
			route: "my-fatoorah",
			permissionCode: "tab-folder-my-fatoorah",
			features: [
				{
					text: "summary",
					icon: "bx-money",
					permissionCode: "tab-folder-my-fatoorah",
					fullRoute: ["my-fatoorah"],
				},
			],
		},

		{
			text: "settings",
			icon: "bx-cog",
			route: "settings",
			permissionCode: "tab-folder-settings",
			features: [
				{
					text: "app-settings",
					icon: "bx-cog",
					fullRoute: ["app-configs"],
					permissionCode: "page-app-config",
				},
				{
					text: "organizations",
					icon: "bx-buildings",
					fullRoute: ["organizations"],
					permissionCode: "tab-organizations",
				},
				{
					text: "bulk-actions",
					icon: "bx-list-ul",
					fullRoute: ["bulk-update-permission-groups"],
					permissionCode: "page-bulk-update-permission-groups",
				},
				{
					text: "restaurants",
					icon: "bx-store-alt",
					fullRoute: ["restaurants", "all"],
					permissionCode: "tab-restaurants",
				},
				{
					text: "otp",
					icon: "bx-shield",
					fullRoute: ["otp"],
					permissionCode: "page-otp-table",
				},
				{
					text: "migrate",
					icon: "bx-data",
					fullRoute: ["migrate"],
					permissionCode: "page-migrate",
				},
				{
					text: "whitelist-otp",
					icon: "bx-key",
					fullRoute: ["whitelist-otps"],
					permissionCode: "page-whitelist-otp",
				},
			],
		},
	];

	isSideBarCollapsed = false;
	lang = Lang.english;

	// dashboard user profile
	dashboardUserProfile: IDashboardUserProfile | undefined;
	isMeRequested = false;

	// routing
	activeRoute!: string;
	isLoggedIn: boolean = false;
	isReportsOpen: boolean = false;
	isRestaurantsOpen: boolean = false;
	isDeliveryOpen: boolean = false;
	isPlansV2Open: boolean = false;
	isCorporateOpen: boolean = false;

	subscriptions: Subscription[] = [];

	isOnline = false;
	restaurant?: Restaurant;
	appConfig?: IAppConfig;
	websiteLink: string | null = null;
	dropdownOpenOrganization = false;
	dropdownOpen = false;
	languageDropdownOpen = false;
	currentLanguage = "english";
	appLanguage = this.appService.getCurrentLanguage();
	hideVisitYourStore: boolean = false;
	restaurants?: Restaurant[];
	selectedRestaurantId: number | null = null;
	selectedRestaurantName: string = "";
	showDropdown: boolean = false;

	constructor(
		private metaService: Meta,
		public appService: AppService,
		private languageService: LanguageService,
		private authService: AuthService,
		private readonly location: Location,
		private connectivityService: ConnectivityService,
		private router: Router,
		private configService: ConfigService,
		private yumealzApiServiceV2: YumealzApiServiceV2,
		private restaurantService: RestaurantService
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.connectivityService.isOnline.subscribe((status) => {
				this.isOnline = status;
			})
		);
		this.subscriptions.push(
			this.appService.isHandset$.subscribe((isHandset) => {
				this.isSideBarCollapsed = isHandset ? true : false;
			})
		);

		this.subscriptions.push(
			this.router.events
				.pipe(
					// Filter for NavigationEnd events
					filter((event) => event instanceof NavigationEnd)
				)
				.subscribe((event: any) => {
					// Retrieve the active route
					this.activeRoute = event.url;
				})
		);
		this.metaService.addTag({
			name: "viewport",
			content: "width=device-width, initial-scale=1.0",
		});

		this.activeRoute = this.location.path().substring(1);

		this.isReportsOpen = this.isActiveRouteGroup("reports");
		this.isRestaurantsOpen = this.isActiveRouteGroup("restaurant");
		this.isDeliveryOpen = this.isActiveRouteGroup("delivery");
		this.isLoggedIn = this.authService.isLoggedIn();
		this.lang =
			this.languageService.getCurrentLang() == Lang.english
				? Lang.english
				: Lang.arabic;

		this.getUserDashboardUserProfile();
		this.customizeSideBarFoldersForOrgType();
		if (this.authService.isLoggedIn()) {
			this.getRestaurant();
		}
		this.currentLanguage = this.lang === "en" ? "english" : "arabic";
	}
	@HostListener("document:click", ["$event"])
	onDocumentClick(event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (
			!target.closest(".org-name") &&
			!target.closest(".language-container") &&
			!target.closest(".organization-options") &&
			!target.closest(".visit-store-dropdown")
		) {
			this.dropdownOpen = false;
			this.dropdownOpenOrganization = false;
			this.languageDropdownOpen = false;
			this.showDropdown = false;
		}
	}
	toggleDropdownOrganization() {
		this.dropdownOpenOrganization = !this.dropdownOpenOrganization;
		if (this.dropdownOpenOrganization) {
			this.languageDropdownOpen = false;
		}
	}

	toggleLanguageDropdown() {
		this.languageDropdownOpen = !this.languageDropdownOpen;
		if (this.languageDropdownOpen) {
			this.dropdownOpenOrganization = false;
		}
	}

	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}

	toggleConnection() {
		this.isOnline = !this.isOnline;
	}

	customizeSideBarFoldersForOrgType() {
		const orgType = this.appService.getCurrentOrgType();
		if (orgType === "gym") {
			const menuIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "menus"
			);
			if (menuIndex !== -1) {
				this.featuresFoldersList.splice(menuIndex, 1);
			}

			const deliveryIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "delivery"
			);
			if (deliveryIndex !== -1) {
				this.featuresFoldersList.splice(deliveryIndex, 1);
			}

			const operationsReportsIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "operation-reports"
			);
			if (operationsReportsIndex !== -1) {
				this.featuresFoldersList.splice(operationsReportsIndex, 1);
			}

			const subscriptionsFolderIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "subscriptions"
			);
			if (subscriptionsFolderIndex !== -1) {
				const subscriptionFolder =
					this.featuresFoldersList[subscriptionsFolderIndex];
				const suborders1Index = subscriptionFolder.features.findIndex(
					(feature) => feature.text === "sub-orders"
				);
				if (suborders1Index !== -1) {
					subscriptionFolder.features.splice(suborders1Index, 1);
				}
			}
		} else if (orgType === "restaurant") {
			const packagesAndProductsIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === this.gymPackagesTabTitle
			);
			if (packagesAndProductsIndex !== -1) {
				this.featuresFoldersList.splice(packagesAndProductsIndex, 1);
			}

			const operationIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "operation"
			);
			if (operationIndex !== -1) {
				this.featuresFoldersList.splice(operationIndex, 1);
			}

			const reportsIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "reports"
			);
			if (reportsIndex !== -1) {
				this.featuresFoldersList.splice(reportsIndex, 1);
			}

			// Remove Classes folder for restaurant org type
			const classesIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "classes"
			);
			if (classesIndex !== -1) {
				this.featuresFoldersList.splice(classesIndex, 1);
			}

			const subscriptionsFolderIndex = this.featuresFoldersList.findIndex(
				(folder) => folder.text === "subscriptions"
			);
			if (subscriptionsFolderIndex !== -1) {
				const subscriptionFolder =
					this.featuresFoldersList[subscriptionsFolderIndex];
				const suborders2Index = subscriptionFolder.features.findIndex(
					(feature) => feature.text === "sub-orders-2"
				);
				if (suborders2Index !== -1) {
					subscriptionFolder.features.splice(suborders2Index, 1);
				}
			}
		}
	}
	isFolderCollapsed(folder: IFeatureFolder) {
		return folder.isOpen ? false : true;
	}

	get shouldShowTestOnTopBar() {
		if (!this.isLoggedIn) return true;
		return window.innerWidth < 800 && window.innerWidth > 480;
	}
	isEnglish(): boolean {
		return this.languageService.lang === Lang.english;
	}

	toggleFolder(folder: IFeatureFolder) {
		if (this.isSideBarCollapsed) this.isSideBarCollapsed = false;
		folder.isOpen = !folder.isOpen;
		if (this.isSideBarCollapsed) this.onSideBarClick(); // To open the sidebar if a folder clicked
	}
	getFeatureClasses(feature: IFeatureItem) {
		const classes = ["features-item"];

		classes.push(feature.icon);
		classes.push(feature.text);

		if (this.activeRoute.substring(1) === feature.route) {
			classes.push("active");
		}

		return classes.join(" ");
	}
	getFeaturesCollapsedClasses(feature: IFeatureItem) {
		const classes = ["bx", "features-item-icon"];
		classes.push(feature.icon);

		// todo implement has Updates
		if (feature.hasUpdates) classes.push("inbox-icon");
		// todo implement number of updates
		return classes.join(" ");
	}

	ngAfterViewInit(): void {}

	get sideBarClassList() {
		let classList = this.isSideBarCollapsed ? "side-bar collapse" : "side-bar";
		if (!this.isLoggedIn) classList += " loggedOut";
		return classList;
	}

	get sideBarMobileBlurClassList() {
		return this.isSideBarCollapsed
			? "side-bar-mobile-blur collapse"
			: "side-bar-mobile-blur";
	}

	get arrowClassList() {
		return this.isSideBarCollapsed
			? "bx bx-arrow-from-left logo-name__icon collapse"
			: "bx bx-arrow-from-right logo-name__icon";
	}
	onSideBarClick() {
		this.isSideBarCollapsed = !this.isSideBarCollapsed;
	}

	onSideBarLinkClick() {
		// Close SideBar on mobile
		if (window.innerWidth < 800) {
			this.isSideBarCollapsed = true;
		}
	}

	toggleReports() {
		this.isReportsOpen = !this.isReportsOpen;
	}

	toggleRestaurants() {
		this.isRestaurantsOpen = !this.isRestaurantsOpen;
	}

	togglePlansV2() {
		this.isPlansV2Open = !this.isPlansV2Open;
	}

	toggleCorporate() {
		this.isCorporateOpen = !this.isCorporateOpen;
	}
	toggleDelivery() {
		this.isDeliveryOpen = !this.isDeliveryOpen;
	}

	changeLanguage(lang: any) {
		this.currentLanguage = lang === "en" ? "english" : "arabic";
		this.languageDropdownOpen = false;
		this.languageService.setLang(lang);
		this.lang = lang;
		location.reload();
	}

	isActiveRoute(routeName: string) {
		// todo fix is active route
		return this.activeRoute === routeName;
	}
	isActiveRouteGroup(routeName: string) {
		// todo fix is active route group
		return this.activeRoute.includes(routeName);
	}

	getUserDashboardUserProfile() {
		this.subscriptions.push(
			this.appService
				.getDashboardUserProfile()
				.subscribe((user) => (this.dashboardUserProfile = user))
		);
	}

	get changeLanguageMessage(): string {
		if (this.languageService.lang === Lang.english) {
			return "تغيير اللغة إلى العربية";
		} else {
			return "Change language to English";
		}
	}
	getUserName() {
		if (this.dashboardUserProfile)
			return (
				this.dashboardUserProfile.name || this.dashboardUserProfile.username
			);
		else return "-";
	}

	logOut() {
		this.authService.logOut();
		location.reload();
	}

	changeOrganization(orgId: number) {
		this.dropdownOpenOrganization = false;
		this.authService.changeOrganization(orgId);
		localStorage.removeItem("needs_onboarding");
	}

	get currentEnv() {
		if (environment.apiUrl.split("/").includes("dev-api.yumealz.com")) {
			return this.lang === "en" ? "TEST" : "تجربة";
		} else if (environment.apiUrl.split("/").includes("core.yumealz.com")) {
			return null;
		} else {
			return this.lang === "en" ? "LOCAL" : "نطاق";
		}
	}

	get currentOrganization() {
		if (this.dashboardUserProfile) {
			const currentOrgId = this.appService.getOrgId();
			return this.dashboardUserProfile.organizations.find(
				(org) => org.id == currentOrgId
			);
		}
		return undefined;
	}

	get otherOrganizations() {
		if (this.dashboardUserProfile) {
			const currentOrgId = this.appService.getOrgId();
			return this.dashboardUserProfile.organizations
				.filter((org) => org.id != currentOrgId)
				.sort((a, b) => {
					const nameToCheck = this.lang === Lang.english ? "techrar" : "تكرار";
					if (a.name_en.toLowerCase() === nameToCheck.toLowerCase()) return -1;
					if (b.name_en.toLowerCase() === nameToCheck.toLowerCase()) return 1;
					if (this.lang === Lang.english) {
						if (a.name_en.toLocaleLowerCase() < b.name_en.toLocaleLowerCase())
							return -1;
						else if (
							a.name_en.toLocaleLowerCase() > b.name_en.toLocaleLowerCase()
						)
							return 1;
						else return 0;
					} else {
						if (a.name_ar.toLocaleLowerCase() < b.name_ar.toLocaleLowerCase())
							return -1;
						else if (
							a.name_ar.toLocaleLowerCase() > b.name_ar.toLocaleLowerCase()
						)
							return 1;
						else return 0;
					}
				});
		}
		return [];
	}
	getRestaurant() {
		const routes = ["restaurants"];

		this.yumealzApiServiceV2
			.getData({
				routes,
			})
			.pipe(take(1))
			.subscribe(
				(res: any) => {
					this.restaurants = res;
					if (this.restaurants && this.restaurants.length > 0) {
						this.selectedRestaurantId = this.restaurants[0].id;
						this.selectedRestaurantName = this.restaurants[0].name_en;
						this.getAppConfig(this.selectedRestaurantId);
					}
				},
				(error) => {
					console.error("Error fetching restaurants:", error);
				}
			);
	}

	getAppConfig(restaurantId: number) {
		if (restaurantId) {
			this.configService.getAppConfig(restaurantId).subscribe((config) => {
				this.appConfig = config;
				this.getHideVisitYourStoreFromMisc(restaurantId);
				this.restaurantService.setRestaurantId(restaurantId);
			});
		}
	}

	visitStore() {
		if (this.appConfig?.url_slug) {
			this.websiteLink =
				"https://" + this.webAppDomain + this.appConfig.url_slug;
			window.open(this.websiteLink, "_blank");
		}
	}

	onRestaurantSelect(restaurant: any) {
		this.selectedRestaurantId = restaurant.id;
		this.selectedRestaurantName = restaurant.name_en;
		this.getAppConfig(restaurant.id);
		this.showDropdown = false;
		this.restaurantService.setRestaurantId(restaurant.id);
	}

	toggleVisitStoreDropdown() {
		this.showDropdown = !this.showDropdown;
	}
	get webAppDomain() {
		return this.appService.isProduction()
			? "app.techrar.com/"
			: "dev-app.techrar.com/";
	}
	canUserView(feature: IFeatureItem) {
		if (feature.isTechrarOnlyInProduction) {
			return this.appService.isProduction()
				? this.appService.isYumealzUser() &&
						this.appService.canView(feature.permissionCode)
				: this.appService.canView(feature.permissionCode);
		}
		return this.appService.canView(feature.permissionCode);
	}
	getHideVisitYourStoreFromMisc = async (restaurantId: number) => {
		const appId = restaurantId;
		if (!appId) return;

		const value = await this.configService.getMiscValue(
			appId,
			"hide_visit_your_store",
			false
		);
		this.hideVisitYourStore = value === "true" || value === true;
	};
	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
