import { IRestaurantProductSize } from "./../../restaurants/models/classes.model";
import { Lang } from "src/app/translate/translation/services/language-service/language.service";
import { ICaptain } from "src/app/report/components/sub-delivery/sub-delivery-datasource";
import { SubOrderStatus } from "../services/app-service/app-service";
import {
	IMenuCategory,
	IMenuPlan,
	IMenuPlanCategory,
} from "src/app/menus/models/classes.model";
import { EPlanVersionDuration } from "src/app/menus/utils/utils";
import { IFulfillment } from "src/app/sector-agnostic/submodules/fulfillments/fulfillments.model";

export interface IBaseModel {
	id: number;
	created_at: Date;
	modified_at: string;
	is_deleted: boolean;
	deleted_at: Date | null;
	ordering: number;
	app_id: number | null;
}

export interface ILightBaseModel {
	id: number;
	created_at?: Date;
	modified_at?: string;
	is_deleted?: boolean;
	deleted_at?: Date | null;
	ordering?: number;
	app_id?: number | null;
	custom_fields?: any;
}
export interface User {
	id?: number;
	username: string;
	password?: string;
	email?: string;
	name?: string;
	date_joined?: Date;
	last_login?: Date;
	current_city_id?: number | null;
	country_code?: string | null;
}

export interface ICustomerProfileReport {
	cities: string[];
	date_joined: Date;
	last_login: Date;
	last_order_at: Date;
	email: string;
	mobile: string;
	name: string;
	id: number;
	last_order_amount: number;
	total_spent_amount: number;
	user: number;
}

export interface ICustomerProfileApp {
	allergies: string[];
	corporate: ICorporate;
	app_id: number;
	is_blocked: boolean;
	is_deleted: boolean;
	join_date: Date;
}

export interface CustomerProfile extends IBaseModel {
	username: string;
	name: string;
	apps_ids: string;
	has_supported_corporate: boolean;
	has_unsupported_corporate: boolean;
	corporate_name_other: null | string;
	customer_payment_id: null | number;
	is_reserved: boolean;
	restaurant_lead_id: null | number;
	user: number;
	corporate_location: number;
	total_spent_amount: number;
	is_active: boolean;
	apps: ICustomerProfileApp[];
	national_id: string;
	secondary_mobile_number: string | null;
	date_of_birth: Date | null;
	avatar: string | null;
	// todo add referral
}

export interface IRestaurantUpdateResults {
	cancelled_sub_orders: number;
	total_sub_orders: number;
}
export interface Restaurant extends IBaseModel {
	name_en: string;
	name_ar: string;
	logo: string;
	main_branch_location: string | null;
	menu: number;
	last_ready_report_date: string;
	start_after: number;
	rest_type: string; // todo change this to "sub" | "demand"| "both"
	subtitle_ar: string | null;
	subtitle_en: string | null;
	description_ar: string | null;
	description_en: string | null;
	is_active: boolean;
	commission: number;
	delivery_windows: number[];
	operation_emails: string[];
	closed_dates: string[];
	update_results: IRestaurantUpdateResults;
	banner: string | null;
	primary_color: string | null;
	secondary_color: string | null;
	mp_commission: number;
	mp_payment_commission: number;
	mp_daily_delivery_price: number;
	saas_commission: number;
	saas_payment_commission: number;
	saas_daily_delivery_price: number;
	supports_pickup: boolean;
	supports_delivery: boolean;
	delivery_by_yumealz: boolean;
	logo_base64: string | null;
	off_days: string;
	store_start_after: number;
	store_last_ready_report_date: string;
}

export interface IAdBanner extends IBaseModel {
	title: string;
	link?: string;
	in_app_link?: boolean;
	image_ar: string;
	image_en: string;
	duration: number;
	expiry_date: string;
	desktop_image_en: string;
	desktop_image_ar: string;
	// front end fields
	imageEnUrl: string | ArrayBuffer | null;
	imageArUrl: string | ArrayBuffer | null;
	desktopImageEnUrl: string | ArrayBuffer | null;
	desktopImageArUrl: string | ArrayBuffer | null;
}

export interface IDeliveryConfig {
	[cityId: number]: boolean;
}

export interface IAppConfig extends IBaseModel {
	merchant_name_id?: string;
	app_id: number;
	enforce_status_validation?: boolean;
	minimal_app_version_ios: string;
	latest_app_version_ios: string;
	minimal_app_version_android: string;
	latest_app_version_android: string;
	maintenance_message_en: string;
	maintenance_message_ar: string;
	contact_method: string;
	contact_data: string;
	enable_referral: boolean;
	enable_wallet: boolean;
	referral_image_ar?: string;
	referral_image_en?: string;
	referral_description_ar: string;
	referral_description_en: string;
	referral_share_message_ar: string;
	referral_share_message_en: string;
	referral_discount_percentage: number;
	referral_max_discount: number;
	checkout_user_agreement_ar: string;
	checkout_user_agreement_en: string;
	app_terms_and_conditions_ar: string;
	app_terms_and_conditions_en: string;
	app_privacy_policy_ar: string;
	app_privacy_policy_en: string;
	show_yumealz_credits: boolean;
	under_maintenance: boolean;
	misc: string;
	onboarding_pages: string;
	ads_banners: IAdBanner[];
	restaurant: number;
	supported_cities_ids: number[];
	delivery_by_yumealz?: IDeliveryConfig | null;
	cashback_percentage: number;
	supplier_code?: number;
	use_logistics_of_app?: number | null;
	enable_receiving_orders: boolean;
	cashback_max_limit: number;
	cashback_min_charge: number;
	firebase_project_batch?: number;
	pause_credits_percentage?: number;
	min_of_pause_days?: number;
	num_of_pause_attempts?: number;
	max_start_date_days?: number;
	auto_sub_order_end_status?: string;
	auto_sub_order_end_in_cities?: number[];
	restricted_voucher_location?: boolean;
	support_multi_branch_delivery: boolean;
	business_type: "restaurant" | "gym";
	max_active_subs?: number;
	prevent_overlapping?: boolean;
	restricted_subscriptions_whitelist?: string[];
	cashback_expiry_days: number;
	resumption_type: ResumptionType;
	enable_subscription_notes?: boolean;
	subscription_notes_ar?: string[];
	subscription_notes_en?: string[];
	auto_verify_customers?: boolean;
	include_fri_with_thu?: boolean;
	logistics_support?: any;
	logistics_support_display?: { string: string };
	migration_in_progress?: boolean;
	migration_allowed_attempts?: number;
	main_city_id?: number;
	currency_iso?: CurrencyISO;
	country_iso?: CountryISO;
	mobile_iso?: MobileISO;
	url_slug?: string;
	primary_foreground_color?: string;
	secondary_foreground_color?: string;
	foreground_color?: string;
	background_color?: string;
	icon?: string;
	business_id?: string;
	subscription_id?: number;
	subscription_details?: ISubscriptionDetails;
	payment_provider?: EPaymentProvider;
}

export interface ISubscriptionDetails {
	id: number;
	status: ESubscriptionDetailsStatus;
	fulfillment: IFulfillment;
	current_period_start_date: string;
	current_period_end_date: string;
	next_billing_date: string;
	auto_renew: boolean;
	history: Array<{
		id: number;
		custom_fields: any;
		pending_invoice: any;
		app_id: number | null;
		created_at: string;
		status: string;
		invoice_status: string;
		invoice: number;
	}>;
}

export enum EPaymentProvider {
	TECHRAR_MYFATOORAH = "techrar_myfatoorah",
	TECHRAR_PAYTABS = "techrar_paytabs",
	CUSTOM_MYFATOORAH = "custom_myfatoorah",
	CUSTOM_PAYTABS = "custom_paytabs",
}
export enum ESubscriptionDetailsStatus {
	Active = "active",
	Paused = "paused",
	Suspended = "suspended",
	Ended = "ended",
	Canceled = "canceled",
}
export enum CurrencyISO {
	KUWAIT_KWD = "KWD",
	SAUDI_ARABIA_SAR = "SAR",
	BAHRAIN_BHD = "BHD",
	UAE_AED = "AED",
	QATAR_QAR = "QAR",
	OMAN_OMR = "OMR",
	JORDAN_JOD = "JOD",
}

export enum CountryISO {
	KUWAIT = "KWT",
	SAUDI_ARABIA = "SAU",
	BAHRAIN = "BHR",
	UNITED_ARAB_EMIRATES = "ARE",
	QATAR = "QAT",
	OMAN = "OMN",
	JORDAN = "JOD",
}

export enum MobileISO {
	KUWAIT = "965",
	SAUDI_ARABIA = "966",
	BAHRAIN = "973",
	UAE = "971",
	QATAR = "974",
	OMAN = "968",
	JORDAN = "962",
}
export interface IPLanVersionPriceV3 extends IBaseModel {
	restaurant_id: number;
	daily_meal_price: number;
	commission: number;
	plan_version: number;
}
export interface IPlanVersionV3 extends IBaseModel {
	min_start_date: string;
	min_start_date_with_menu_restrictions: string;
	prices: IPLanVersionPriceV3[];
	num_of_days: number;
	off_days: string[]; //todo make it an array of weekdays
	total_num_of_items: number;
	daily_delivery_price: number;
	delivery_price: number;
	original_price: number;
	discount_percentage: number;
	max_start_date: string;
	is_active: boolean;
	duration: EPlanVersionDuration | null;
	// only when retrieve plan version the type of restaurant_branch is IRestaurantBranch else it is number
	restaurant_branch?: IRestaurantBranch;
	restaurant_branch_id: number;
	plan: ILightPlanV3 | null;
	plan_id: number;
	pause_credits: number;
	num_of_pause_attempts: number;
	min_of_pause_days: number;
}

export interface IPlanVersionListV3 extends IBaseModel {
	// todo make it as the response
	daily_delivery_price: number;
	delivery_price: number;
	discount_percentage: number;
	is_active: boolean;
	max_start_date: Date;
	min_start_date: Date;
	num_of_days: number;
	off_days: string[];
	original_price: number;
	prices: IPLanVersionPriceV3[];
	plan: IPlanVersionListPlanV3;
	total_number_of_items: number;
}
// todo use ILightPlan and remove IPlanVersionListPlan
export interface IPlanVersionListPlanV3 extends IBaseModel {
	// todo make it as the response
	name_ar: string;
	name_en: string;
}

export interface ILightPlanV3 extends IBaseModel {
	name_en: string;
	name_ar: string;
	daily_num_of_items: number;
	daily_num_of_meals: number;
}
export interface IPlanDateV3 {
	id: number;
	restaurant_name: string;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	ordering: number;
	restaurant: number;
	plan: number;
	plan_categories: IPlanCategoryV3[];
	master_id?: number;
	day: string;
	repetition_counter: number;
	// now it is a ui calculated field
	date: string;
}

export interface IPlanCategoryV3 {
	id: number;
	created_at: Date;
	deleted_at: Date | null;
	is_deleted: boolean;
	is_multi_select: boolean;
	max_selected_options: number;
	modified_at: Date;
	name_ar: string;
	name_en: string;
	ordering: number;
	plan_category_id: number;
	options: IPlanOptionV3[];
}
export interface IPlanOptionV3 {
	id: number;
	created_at: Date;
	deleted_at: Date | null;
	modified_at: Date;
	is_deleted: boolean;
	ordering: number;
	is_active: boolean;
	is_recommended: boolean;
	is_selected: boolean;
	plan_option_id: number;
	product: IProduct;
}

export interface IPlanTag extends IBaseModel {
	is_active: boolean;
	corporate: ICorporate | null;
	tag_ar: string;
	tag_en: string;
	type_ar: string;
	type_en: string;
	description_en: string;
	description_ar: string;
	color: string;
	image: string;
	is_featured: boolean;
	featured_plan_id: number;
	pause_credits: number;
	num_of_pause_attempts: number;
	min_of_pause_days: number;
}
export enum ELocationType {
	Home = "home",
	Office = "office",
	Other = "other",
	Friend_House = "friend_house",
}
export interface ILocation extends IBaseModel {
	city: ICity;
	zone: IZone;
	name_ar: string;
	name_en: string;
	location_url: string;
	longitude: string;
	latitude: string;
	address1: string;
	address2: string;

	place_id: string;
	postal_code: string;
	formatted_address: string;
	district: string;
	district_ar: string;

	city_name_en: string;
	city_name_ar: string;
	route: string;
	street_number: string | null;
	is_synced: boolean;

	customer_profile: number;
	description: string;
	is_default: boolean;
	type: ELocationType;
	image: string;
}

export interface IDistrictGroup extends IBaseModel {
	name: string;
	districts: string[];
}

export interface ICityDistricts {
	city_id: number;
	city_name_en: string;
	districts: string[];
}
export interface ICity extends IBaseModel {
	name_ar: string;
	name_en: string;
	country_code: null;
	keywords: null;
	is_supported: false;
}

export enum EServiceType {
	Yumealz_Delivery = "yumealz_delivery",
	Other_Delivery = "other_delivery",
	Pickup = "pickup",
	All = "all",
}

export interface ISubOrderV3 extends IBaseModel {
	can_change_location: boolean;
	can_change_branch: boolean;
	can_change_meals: boolean;
	captain: ICaptain;
	date: string;
	delivered_at: Date;
	delivery_window?: IDeliveryWindow;
	location_id?: number;
	location?: ILocation;
	plan_date: number;
	reference_code: null;
	restaurant: Restaurant;
	selected_plan_categories: ISelectedPlanCategory[];
	status: string; //todo make this enum
	subscription: number; //todo check later if it should be named *_id
	meals_changed: boolean;
	delivery_window_changed: boolean;
	location_changed: boolean;
	postponed: boolean;
	is_complained: boolean;
	complain_notes: string;
	operation_notes: string;
	notes: string;
	is_pickup: boolean;
	restaurant_branch?: IRestaurantBranch | null;
	restaurant_branch_id: number | null;
	zone: IZoneSubOrder;

	// frontend
	selected: boolean;
	isHidden: boolean;
}
export interface IDetailedSubOrderV3 extends IBaseModel {
	can_change_location: boolean;
	can_change_meals: boolean;
	captain?: ICaptain;
	customer_profile: ICustomerProfile2;
	plan: ILightPlanV3;
	date: Date;
	delivered_at: Date;
	delivery_window?: IDeliveryWindow;
	location_id?: number;
	location?: ILocation;
	city_id: number;
	plan_date: number;
	reference_code: null;
	restaurant: Restaurant;
	selected_plan_categories: ISelectedPlanCategory[];
	status: string; //todo make this enum
	service_type: EServiceType;
	operation_statuses: IOperationStatus[];
	subscription: number; //todo check later if it should be named *_id
	meals_changed: boolean;
	restaurants: Restaurant[];
	location_changed: boolean;
	delivery_window_changed: boolean;
	postponed: boolean;
	is_complained: boolean;
	is_first_day: boolean;
	complain_notes: string;
	operation_notes: string;
	secondary_mobile_number?: number;
	notes: string;
	is_pickup: boolean;
	restaurant_branch?: IRestaurantBranch;

	estimated_delivery_time?: Date;
	received_by_captain_at?: Date;

	// price fields
	daily_delivery_price: number;
	daily_meal_price: number;
	meal_discount_percentage: number;
	daily_meal_price_after_discount: number;
	meal_voucher_amount: number;
	delivery_voucher_amount: number;
	total_voucher_amount: number;
	voucher_afforded_by_restaurant: boolean;
	was_auto_ended: boolean;
	handled_to_captain_at?: Date;
	can_change_branch: boolean;
	logistics_provider: {
		id: number;
		logo: string;
		name_en: string;
		name_ar: string;
		can_access_provider: boolean;
	};
}

export interface IOperationStatus extends IBaseModel {
	name_en: string;
	name_ar: string;
	processed_at: Date;
}
export interface IDeliveryWindow {
	id: number;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	deleted_at: Date;
	ordering: number;
	type: string;
	from_time: string;
	to_time: string;
	is_default: boolean;
	is_active: boolean;
	tag: string;
	tag_en: string;
	tag_ar: string;
	app_id?: number | null;
}

export interface ILightPlanV3 {
	id: number;
	name_en: string;
	name_ar: string;
}

export interface ISelectedPlanCategory {
	id: number;
	created_at: Date;
	deleted_at: Date | null;
	is_deleted: boolean;
	is_multi_select: boolean;
	max_selected_options: number;
	modified_at: Date;
	name_ar: string;
	name_en: string;
	ordering: number;
	plan_category_id: number;
	selected_options: ISelectedOption[];
}

// todo enhance the name of this interface
export interface ISelectedOption {
	created_at: Date;
	deleted_at: Date | null;
	id: number;
	is_active: boolean;
	is_deleted: boolean;
	is_recommended: boolean;
	is_selected: boolean;
	modified_at: Date;
	ordering: number;
	plan_option_id: number;
	product: IProduct;
	is_compensation: boolean;
	extra_item_config_id: number | null;
	quantity?: number; //extra field for extra item deletion
	category_ar?: string;
	category_en?: string;
}

export interface IProduct extends IBaseModel {
	description_ar: string;
	description_en: string;
	discount_percentage: number;
	image: string;
	is_sub: boolean;
	name_ar: string;
	name_en: string;
	original_price: number;
	restaurant_id: number;
	is_active: boolean;
	modifiers: []; //TODO fix this type
	is_combo_item_only: boolean;
	show_in_plans_only: boolean;
	category: number;
	size: null; //todo fix this type
	stock: number;
	max_stock: number;
	closed_dates: string[];
	off_days: string[];
	nutritions: INutritions;
	nutrition_options: INutritions[];
	is_sizable: boolean;
	buy_price: number;
	is_special: boolean;
	symbol: string;
	tags: string[];
	allergies: string[];

	// only used in frontend
	is_recommended?: boolean;
	valuesChanged?: boolean;
	showDetails: boolean;
	foundInSearch: boolean;
}

export interface INutritions extends IBaseModel {
	calories: number;
	carbs: number | null;
	protein: number | null;
	fat: number | null;
	product: number; //todo check later if it should be named *_id
	size: number | null; //todo check later if it should be named *_id
}

export interface IAccount {
	id: number;
	username: string;
	name: string;
	email: string;
	lang: string;
}

// todo remove this
export interface ICustomerProfile2 {
	id: number;
	created_at: Date;
	modified_at: Date;
	account: IAccount;
}

export interface ICustomersReportItem {
	id: number;
	name: string;
	mobile: string;
	email: string;
	total_spent_amount: number;
	last_order_amount: number;
	last_order_at: Date;
	last_login: Date;
	date_joined: Date;
	cities: string[];
}

export interface ISubscriptionV3 extends IBaseModel {
	customer_profile?: ICustomerProfile2;
	customer_name?: string;
	city_id: number;
	customer_id?: number;
	secondary_mobile_number?: number;
	name_ar: string;
	name_en: string;
	start_date: Date;
	end_date: Date;
	status: string; // todo change this to subscription statuses
	num_of_items: number;
	num_of_days: number;
	num_of_remaining_days: number;
	avg_daily_meal_calories: number;
	daily_meal_price: number;
	delivery_price: number;
	delivery_window: number;
	discount_percentage: number;
	max_pause_date: string;
	min_pause_date: string;
	max_resume_date: string;
	min_resume_date: string;
	notes: string;
	original_price: number;
	plan: IMenuPlan;
	plan_version: IPlanVersionV3;

	primary_location?: ILocation;
	primary_location_id: number;
	secondary_location?: ILocation;
	secondary_location_id: number;
	selected_options_ids: number[];
	sub_orders: ISubOrderV3[];

	cancel_credits: number;
	paused_days: number;
	change_location_credits: number;
	is_saas: boolean;
	is_pickup: boolean;
	restaurants: Restaurant[];
	daily_delivery_price: number;
	order_details: {
		id: number;
		cart_discounts: number;
		delivery_fee: number;
		delivery_fee_discounts: number;
		redeem_discounts: number;
		total_amount: number;
		total_cart_amount: number;
		total_discounts: number;
		voucher_discount: number;
		voucher_code: null | string;
	};
	order_id: number;
	voucher_id: number;
	voucher_code: string;
	is_first_subscription: boolean;
	service_type: EServiceType;
	refund_method?: "manually" | "bank" | "wallet";
	corporate: number;
	pause_attempts_credits: number;
}
export interface IExtraItemConfig extends IBaseModel {
	complaint: number;
	created_at: Date;
	delivered_sub_orders: number;
	is_compensation: boolean;
	plan_menu_category: IMenuPlanCategory;
	quantity: number;
	sub_orders: number[];
	subscription: number;
	total_sub_orders: number;
}

export interface IRestaurantCategory {
	id: number;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	deleted_at?: Date;
	ordering: number;
	name_ar: string;
	name_en: string;
	image?: string;
	is_sub: boolean;
	is_active: boolean;
	menu: number;
	products: IProduct[];
	restaurant_id: number;

	// frontend fields
	isDeleteAction: boolean;
}

export interface ICreatePlanCategoryDataV3 {
	name_en: string;
	name_ar: string;
	max_selected_options: number;
	options: number[];
	recommended_options: number[];
}

export interface ICreatePlanDayDataV3 {
	day: string;
	categories: ICreatePlanCategoryDataV3[];
}

export interface ICreatePlanVersionDataV3 {
	daily_meal_price: number;
	daily_delivery_price: number;
	discount_percentage: number;
	num_of_days: number;
	off_days: string[];
	commission: number;
	restaurant_branch_id: number;
}
export interface ICreatePlanDataV3 {
	restaurant_id: number;
	name_en: string;
	name_ar: string;
	plan_tags_ids: number[];
	subtitle_ar?: string | null;
	subtitle_en?: string | null;
	avg_daily_meal_calories: number | null;

	start_after: string;
	description_ar?: string | null;
	description_en?: string | null;

	next_scheduled_day: string | null;
	repetition: number;
	daily_num_of_meals: number;
	start_date: string | null;
	days: ICreatePlanDayDataV3[];
	versions: ICreatePlanVersionDataV3[];

	off_days: string[];
	end_date: string;
}

// Wallet Models

export enum EWalletPointType {
	Complaint = "complaint",
	Cashback = "cashback",
	Cancellation = "cancellation",
	Redeem = "redeem",
	Refund = "refund",
	Gift = "gift",
	Referral = "referral",
	Expired = "expired",
	Withdrawn = "withdrawn",
	Bank_Transfer = "bank_transfer",
	Topup = "topup",
}

export interface IWalletPoint {
	id: number;
	created_at: Date;
	modified_at: Date;
	type: EWalletPointType;
	associated_instance_id: number | null; // if complaint, associated_instance_id = complaint_id, if referral, associated_instance_id = voucher_id, if cashback, associated_instance_id = order_id, if cancellation, associated_instance_id = order_id, if redeem, associated_instance_id = order_id, if refund, associated_instance_id = order_id, if gift, associated_instance_id = customer_profile_id
	amount: number;
	expiration_date: Date;
	conversion_rate: number;
	is_refundable: boolean;
	issued_by_id: number;
	linked_point: number | null;
	is_deleted: boolean;
	wallet: number;
	ordering: number;
	deleted_at: Date | null;
	issuer: {
		username: string;
		name: string;
	};
}

export interface IFinancialReport {
	sub_orders: IFinancialReportSubOrder[];
	sales: {
		delivery_sales: number | null;
		meals_sales: number;
		sales: number | null;
	};
	yumealz: {
		commission: {
			commission: number;
			vat_percentage: number;
			commission_vat: number;
			total_commission: number;
		};
		delivery_voucher_cost: number;
		meals_voucher_cost: number;
		voucher_cost: number;
		complaints_cost: number;
		redeem_cost: number;
		revenue: number;
	} | null;
	restaurant: {
		commission: {
			commission: number;
			vat_percentage: number;
			commission_vat: number;
			total_commission: number;
		};
		delivery_voucher_cost: number;
		meals_voucher_cost: number;
		voucher_cost: number;
		complaints_cost: number;
		revenue: number;
	};
}

// todo remove this when discarding old financial report
export interface IFinancialReportSubOrder {
	id: number;
	daily_delivery_price: number | null;
	redeem_cost: number | null;
	sub_order_price: number | null;
	restaurant_id: number;
	subscription_id: number;
	order_id: number;
	customer_profile_id: number;
	date: string;
	plan_name_en: string;
	plan_name_ar: string;
	num_of_days: number;
	daily_meal_price: number;
	meal_discount_percentage: number;
	daily_meal_price_after_disc: number;
	commission: number;
	commission_sar: number;
	voucher: IFinancialReportVoucher | null;
	customer_name: string;
}

export interface ISubOrderFinancialVoucher {
	code: string;
	id: number;
	afforded_by_restaurant: boolean;
	meal_amount: number;
	delivery_amount: number | null;
	total_amount: number;
}

export interface ISubOrderFinancial {
	id: number;
	subscribed_at: Date;
	app_id: number | null;
	customer_name: string;
	restaurant_id: number;
	subscription_id: number;
	order_id: number;
	customer_profile_id: number;
	date: string;
	plan_name_en: string;
	plan_name_ar: string;
	num_of_days: number;
	daily_meal_price: number;
	daily_delivery_price: number;
	meal_discount_percentage: number;
	daily_meal_price_after_disc: number;
	commission: number;
	commission_sar: number;
	sub_order_price: number;
	redeem_cost: number;
	status: SubOrderStatus;
	voucher: ISubOrderFinancialVoucher | null;
	is_pickup: boolean;
}

export interface IGroupedSubOrderFinancial extends ISubscriptionV3 {
	delivered_sub_orders: number;
}
export interface IFinancialReportVoucher {
	meal_amount: number;
	delivery_amount: number;
	total_amount: number;
	id: number;
	code: string;
	afforded_by_restaurant: boolean;
}

export interface IWallet extends IBaseModel {
	points: IWalletPoint[];
	conversion_rate: number;
	balance: number;
	refundable_balance: number;
	is_locked: boolean;
	customer_profile: number;
}

// End of Wallet Models

// Start of Complaints Models

export enum EComplaintStatus {
	Pending = "pending",
	In_Process = "in_process",
	Resolved = "resolved",
	Rejected = "rejected",
}

export enum EComplaintTo {
	Yumealz = "yumealz",
	Restaurant = "restaurant",
	Captain = "captain",
}

export interface IComplaintReason {
	id: number;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	deleted_at: Date | null;
	ordering: number;
	title_en: string;
	title_ar: string;
	is_active: boolean;
}

export interface IComplaint extends IBaseModel {
	sub_order: IDetailedSubOrderV3;
	reason: IComplaintReason;
	customer_profile_id: number;
	description: string;
	image: string;
	complaint_to: EComplaintTo;
	reviewer_id: number;
	review_notes: string;
	compensation_amount: number;
	status: EComplaintStatus;
	resolved_at: Date | null;
	tag?: IComplaintTags | null;
	compensation_type?: "meal" | "point";
	issued_by: string | null;
}

// End of Complaints Models

// Start of Dashboard Profile Models

export interface IDashboardProfileRestaurant {
	id: number;
	name_en: string;
	name_ar: string;
	logo: string;
	start_after: number;
	last_ready_report_date: string;
	operation_emails: string;
}
export interface IDashboardProfileGroup {
	// todo change name to enum
	name: string;
	id: number;
}
export interface IDashboardUserProfile {
	id: number;
	username: string;
	name: string;
	email: string;
	lang: Lang;
	is_email_verified: boolean;
	is_mobile_verified: boolean;
  customer_id?: number;
	// restaurants: IDashboardProfileRestaurant[];
	organizations: {
		id: number;
		name_en: string;
		name_ar: string;
		logo: string;
		org_type: string;
	}[];
	permissions: string[];
}
// End of Dashboard Profile Models

// Start of Orders App

export interface ICartItem {
	id: number;
	name: string;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	deleted_at: Date | null;
	ordering: number;
	quantity: number;
	price: number;
	cart: number;
	ordered_combo: number | null;
	ordered_product: number | null;
	combo_id: number | null;
	product_id: number | null;

	subscription: number | null;

	// front end only
	is_accepted: boolean;
}
export interface ICart {
	id: number;
	items: ICartItem[];
	price: number;
	created_at: Date;
	modified_at: Date;
	is_deleted: boolean;
	deleted_at: Date | null;
	ordering: number;
	is_confirmed: boolean;
	customer_profile: number;
}

export interface IPayment extends IBaseModel {
	authorization_code: "SUCCESS" | "FAILED" | "REFUNDED";
	currency: CurrencyISO;
	gateway_ref: string;
	merchant_ref: string;
	order: number;
	payment_method: string; // todo add payment method as enum
	quoted_amount: number;
	received_amount: number;
	status: string; //todo add status as enums
	transaction_type: "payment" | "refund";
	provider: "myfatoorah" | "paytabs" | "tamara" | "tabby";
}
export interface IPaymentRefund {}
export interface IOrder extends IBaseModel {
	cart: ICart;
	restaurant: Restaurant;
	payments: IPayment[];
	created_at: Date;
	deleted_at: Date | null;
	customer: User;
	customer_id: number;
	restaurant_id: number;
	restaurants_ids: number[];
	location_id: number;
	status: number;
	is_sub: boolean;
	is_paid: boolean;
	is_saas: boolean;
	is_pickup: boolean;
	item: string | null;
	prices: number;
	location: ILocation; // todo verify it is Ilocation model
	delivery_window: IDeliveryWindow;
	is_deleted: boolean;
	ordering: number;
	is_delivered_to_corporate: boolean;
	voucher_id: number | null;
	type: string; // todo make it enum
	notes: string;
	total_cart_amount: number;
	delivery_fee: number;
	cart_price_discounts: number;
	delivery_fee_discounts: number;
	total_discounts: number;
	total_amount: number;
	redeem_discounts: number;
	captain?: ICaptain;
	delivered_at: Date | null;
	invoice_file?: string;
	invoice_serial?: string;
	payment_attachment?: string;
	delivery_date: string;
}

// this is the correct order status
export enum EOrderStatus {
	New = 100, // After confirming the cart
	Pending = 101, // When going to checkout. (Create payment during this)
	Confirmed = 102, // IF payment was successful
	On_Delivery = 103,
	Delivered = 104,
	Cancelled_By_Customer = 105,
	Cancelled_By_Operation = 106,
	Cancelled_By_Payment_Failure = 107,
}

// End of Orders App

export interface ICorporate extends IBaseModel {
	name_ar: string;
	name_en: string;
	domain: string;
	logo: null;
}

export interface ICorporateLocation extends IBaseModel {
	corporate: ICorporate;
	name_ar: string;
	name_en: string;
	mobile_number: string;
	secondary_mobile_number: string;
	floor_number: string;
	office_number: string;
	description: null;
	cashback_percentage: number;
	location: ILocation;
	delivery_window: number;
}

export interface IZoneVertex {
	lat: number;
	lng: number;
}

export interface IZone extends IBaseModel {
	name: string;
	type: string; // TODO make it enum
	description: string;
	is_active: boolean;
	city?: ICity;
	delivery_windows_options?: IDeliveryWindow[];
	polygon?: IZoneVertex[];
	captain?: ICaptain | null;
	auto_assign_captain: boolean;
	color: string;
}

export interface IRestaurantBranch extends IBaseModel {
	name_en: string;
	name_ar: string;
	on_demand_opened: boolean;
	on_demand_delivery_price: number;
	restaurant: number;
	location: ILocation;
	handler?: ICaptain;
	is_active: boolean;
	is_main: boolean;
	branch_hours?: string;
	on_demand_min_charge: number;
	gender?: string;
}

export interface IRestaurantBranchAvailability extends IBaseModel {
	delivery_window: IDeliveryWindow;
	is_opened: boolean;
	max_orders: number;
	branch: number;
}

export interface IComboSizeOption extends IBaseModel {
	menu: number;
	name_ar: string;
	name_en: string;
}

export interface IComboItemCategoryOption extends IBaseModel {
	product: IProduct;
	is_recommended: boolean;
	is_active: boolean;
	is_optional: boolean;
	combo_item_category: number;
}
export interface IComboItemCategory extends IBaseModel {
	options: IComboItemCategoryOption[];
	name_ar: string;
	name_en: string;
	is_optional: boolean;
	is_multi_select: boolean;
	max_selected_options: number;
	combo: number;
}
export interface IRestaurantCombo extends IBaseModel {
	name_ar: string;
	name_en: string;
	description_ar: string;
	description_en: string;
	image: string;
	size: IComboSizeOption | null;
	item_categories: IComboItemCategory[];
	is_active: boolean;
	original_price: number;
	discount_percentage: number;
	category: number;
	stock: number;
	max_stock: number;
	off_days: string[];
	closed_dates: string[];

	// front end only
	valuesChanged?: boolean;
}

export interface MpRestaurantInvoice extends IBaseModel {
	sales_qty: number;
	sales_rate: number;
	sales_amount: number;
	successful_payment_qty: number;
	successful_payment_rate: number;
	successful_payment_amount: number;
	cancelled_payment_qty: number;
	cancelled_payment_rate: number;
	cancelled_payment_amount: number;
	delivered_sub_orders_qty: number;
	delivered_sub_orders_rate: number;
	delivered_sub_orders_amount: number;
	complaints_qty: number;
	complaints_rate: number;
	complaints_amount: number;
	fines_qty: number;
	fines_rate: number;
	fines_amount: number;
	monthly_subscription_qty: number;
	monthly_subscription_rate: number;
	monthly_subscription_amount: number;
	total_amounts: number;
	total_discounts: number;
	total_before_vat: number;
	vat: number;
	total_after_vat: number;
	total_due_invoice: number;
	restaurant: number;
	period: number;
	invoice_file: string | null;
}
export enum EPaymentPeriodStatus {
	Forthcoming = "Forthcoming",
	Ready = "Ready",
	Processing = "Processing",
	Completed = "Completed",
}

export interface IPaymentPeriod extends IBaseModel {
	from_date: string;
	to_date: string;
	status: EPaymentPeriodStatus;
}
// Start of invoice

export interface IInvoice extends IBaseModel {
	number: string | null;
	invoice: string | null;
	sent: boolean;
	sub_order: IDetailedSubOrderV3;
	date: string;
}
// End of invoice

// Start of organizations

export interface IOrganization extends IBaseModel {
	restaurants: ILightRestaurant[];
	name_ar: string;
	name_en: string;
	org_type: string;
	is_active: boolean;
	logo: string;
	contact: string | null;
	permissions: IPermission[];
	business_type: "gym" | "restaurant" | "both";
}

export enum EOrgType {
	Marketplace = "marketplace",
	SaaS = "saas",
	Both = "both",
}

export interface IPermission extends IBaseModel {
	id: number;
	name: string;
	codename: string;
	content_type: number;
}

export interface IPermissionGroup extends IBaseModel {
	name: string;
	description?: string;
	group_type: string;
	tag?: string;
	permissions: IPermission[];
}

export interface ILightRestaurant extends IBaseModel {
	id: number;
	name_ar: string;
	name_en: string;
	logo: string;
}

export interface IOrgRestaurantAccess extends IBaseModel {
	restaurant: ILightRestaurant;
	is_active: boolean;
	permissions: IPermission[];
}

export interface IOrgUserProfile extends IBaseModel {
	user: User;
	is_admin: boolean;
	is_active: boolean;
	org_restaurant_accesses: IOrgRestaurantAccess[];
	organization: number;
}
// End of organizations

// Start of Notification

export interface INotification extends IBaseModel {
	result: { [key: string]: any };
	type: string;
	receivers: string;
	title: string;
	body: string;
	data: string | null;
	template: any;
}

export interface IServiceKey {
	name: string;
	type: string;
	is_secret: boolean;
	is_required: boolean;
}

export interface IService extends IBaseModel {
	keys: IServiceKey[];
	connector_id: number | null;
	name: string;
	custom_name: string;
	title_en: string | null;
	title_ar: string | null;
	description_en: string;
	description_ar: string;
	website_url: string | null;
	phone_number: string | null;
	email: string | null;
	privacy_policy_url: string | null;
	faq_url: string | null;
	doc_guide_url: string | null;
	logo: string | null;
	is_system: boolean;
	type: number;
	is_active: boolean;
	is_active_connector: boolean;
	category: string;
}

export interface Integration extends IBaseModel {
	services: IService[];
	name: string;
	title_en: string | null;
	title_ar: string | null;
	allow_multiple: boolean;
}

export enum ResumptionType {
	AUTO = "auto",
	MANUAL = "manual",
	FORCED_MANUAL = "forced_manual",
}
export interface IZoneSubOrder {
	id: number;
	name: string;
}
export interface IComplaintTags extends IBaseModel {
	tag_en: string;
	tag_ar: string;
	type_en: string;
	type_ar: string;
}

export interface IMerchantRegister {
	username: string;
	email: string;
	step?: number;
	brand_name?: string;
	business_type?: string;
	account_created?: boolean;
}

export interface IRegisterConfirmation {
	package_sku: string;
	brand: {
		name_en: string;
		name_ar?: string;
		business: string;
	};
	account: {
		username: string;
		name: string;
		email: string;
	};
	plan?: {
		name_en?: string;
		name_ar?: string;
		duration?: string;
		price?: string;
	};
	otp?: string;
}
