import { Directive, ElementRef, Input } from "@angular/core";
import { AppService } from "../services/app-service/app-service";
import { firstValueFrom, take } from "rxjs";
import { CurrencyISO } from "../models/classes.model";

@Directive({
	selector: "[appCurrency]",
})
export class AppCurrencyDirective {
	// string type is used to allow passing only appCurrency in other components
	@Input("appCurrency") appId?: number | null | string;
	@Input() color?: string; // Add color input parameter

	constructor(private el: ElementRef, private appService: AppService) {}

	ngOnInit() {
		this.setCurrency();
	}
	async setCurrency() {
		if (this.appService.isYumealzUser()) {
			this.setSARSvg();
			return;
		}
		if (typeof this.appId === "string") this.appId = undefined;

		if (!this.appId) {
			const appsIds = this.appService.getAppIds();
			const currencies: CurrencyISO[] = await Promise.all(
				appsIds.map((appId) =>
					firstValueFrom(this.appService.getCurrency(appId))
				)
			);

			const firstCurrency = currencies[0];
			// check if all currencies are the same
			const allCurrenciesAreSame = currencies.every(
				(currency) => currency === firstCurrency
			);
			if (allCurrenciesAreSame) {
				if (firstCurrency === CurrencyISO.SAUDI_ARABIA_SAR) {
					this.setSARSvg();
				} else {
					this.el.nativeElement.textContent =
						this.appService.translate(firstCurrency);
				}
			} else {
				this.appService.notifyFailure(
					this.appService.translate("you-have-multiple-currencies")
				);
				this.el.nativeElement.textContent = "???";
			}
		} else {
			const currency = await firstValueFrom(
				this.appService.getCurrency(this.appId)
			);
			if (currency === CurrencyISO.SAUDI_ARABIA_SAR) {
				this.setSARSvg();
			} else {
				this.el.nativeElement.textContent = this.appService.translate(currency);
			}
		}
	}

	private setSARSvg() {
		const fillColor = this.color || "currentColor";
		const svgString = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 536 600" width="16" height="18">
			<path d="M 252.14 0.00 L 252.26 0.00 C 252.31 96.87 252.25 193.75 252.28 290.62 C 273.25 286.07 294.25 281.65 315.25 277.22 C 315.39 212.98 315.22 148.72 315.33 84.48 C 332.81 63.00 354.37 45.18 378.38 31.43 C 378.36 108.88 378.39 186.32 378.36 263.77 C 430.89 252.58 483.42 241.30 535.98 230.27 C 533.64 253.68 527.57 277.14 517.69 298.52 C 515.15 299.45 512.37 299.78 509.73 300.40 C 465.95 309.77 422.12 318.99 378.35 328.41 C 378.41 349.89 378.34 371.36 378.38 392.84 C 430.93 381.74 483.44 370.43 535.99 359.33 C 533.70 382.49 527.74 405.22 518.29 426.48 C 517.59 428.49 515.20 428.21 513.49 428.73 C 447.42 442.72 381.37 456.92 315.30 470.86 C 315.28 427.90 315.32 384.93 315.28 341.96 C 294.23 346.08 273.27 350.81 252.28 355.22 C 252.29 378.82 252.26 402.41 252.29 426.01 C 252.36 431.90 250.89 437.76 247.86 442.83 C 239.04 456.28 229.87 469.54 220.94 482.93 C 217.06 488.53 213.37 494.78 208.79 499.79 C 201.03 508.06 190.88 514.01 179.81 516.49 C 119.87 529.27 59.93 542.00 0.00 554.78 L 0.00 554.18 C 2.45 531.27 8.30 508.79 17.65 487.72 C 18.15 486.15 19.35 486.12 20.75 485.72 C 72.75 474.70 124.73 463.60 176.71 452.54 C 180.93 451.56 185.09 450.98 189.20 449.65 C 189.03 426.10 189.19 402.55 189.13 379.00 C 189.08 375.41 189.39 372.12 188.77 368.58 C 185.69 369.39 182.58 370.06 179.47 370.70 C 129.54 381.32 79.60 391.97 29.66 402.57 C 32.09 379.37 37.99 356.60 47.50 335.29 C 47.83 334.34 48.56 333.81 49.68 333.68 C 95.66 323.95 141.71 314.07 187.70 304.35 C 188.70 304.24 189.18 303.54 189.13 302.25 C 189.13 219.18 189.13 136.11 189.13 53.03 C 206.61 31.64 228.09 13.59 252.14 0.00 Z" fill="${fillColor}" data-index="0" style="opacity: 1;"></path>
			<path d="M 536.00 488.39 L 536.00 489.12 C 533.47 511.96 527.75 534.34 518.38 555.36 C 517.91 556.52 517.18 557.15 516.20 557.25 C 449.22 571.45 382.21 585.82 315.22 599.96 C 317.67 576.36 323.77 553.19 333.57 531.56 C 400.98 517.06 468.56 502.81 536.00 488.39 Z" fill="${fillColor}" data-index="1"></path>
		</svg>`;
		this.el.nativeElement.innerHTML = svgString;
	}
}
