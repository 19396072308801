import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { AuthService } from "../services/auth-service/auth.service";
import { JwtService } from "../services/jwt-service/jwt.service";
import {
	Lang,
	LanguageService,
} from "src/app/translate/translation/services/language-service/language.service";
import * as version from "src/environments/version.json";
import { SKIP_INTERCEPTOR_FOR_TRANSLATION } from "src/app/translate/translation/services/translation-loader/translation-loader.service";
import { environment } from "src/environments/environment";

export enum ApiErros {
	// General Errors
	serverConnection = "server_error",
	unauthrizedAccess = "unauthorized_access",
	otherError = "something_went_wrong",
	wrongCredentials = "wrong_credentials",
	meError = "me_error",
}

@Injectable({
	providedIn: "root",
})
export class HttpApiInterceptorService implements HttpInterceptor {
	appVersion = version;
	private isRefreshing = false;
	lang = this.languageService.getCurrentLang();
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);
	constructor(
		private jwtServie: JwtService,
		private authService: AuthService,
		private matSnackBar: MatSnackBar,
		private languageService: LanguageService
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

    // skip interceptor if it is external api (not techrar api), see usage in translation.
		if (req.context.get(SKIP_INTERCEPTOR_FOR_TRANSLATION)) {
			return next.handle(req);
		}

		const reqWithToken = this.addToken(req);
		const reqWithLang = this.addLang(reqWithToken);
		const reqWithOSAndVersion = this.addOSAndVersion(reqWithLang);
		const reqWithAppId = this.addAppId(reqWithOSAndVersion);
		return next.handle(reqWithAppId).pipe(
			catchError((err) => {
				// todo use errMsg
				const errMsg: string = err?.error?.error?.messages[0];
				const okText = this.lang == Lang.english ? "Ok" : "حسنا";

				const errStatus = err.status;
				if (errStatus >= 500) {
					err = ApiErros.serverConnection;

					const message =
						this.lang == Lang.english
							? "Oops! Something went wrong. Please Contact Us!"
							: "عذرًا! حدث خطأ ما. يرجى التواصل معنا!";

					this.matSnackBar.open(message, okText, {
						duration: 10000,
					});
				} else if (errStatus == 401) {
					return this.handle401Error(reqWithAppId, next);
				} else {
					console.log(err);
					const systemErrorText =
						this.lang == Lang.english ? "System Error" : "خطأ في النظام";
					if (
						err &&
						err.error &&
						err.error["error"] &&
						err.error["error"]["messages"] &&
						err.error["error"]["messages"][0]
					) {
						this.matSnackBar.open(
							systemErrorText + " " + err.error["error"]["messages"][0],
							okText,
							{
								duration: 10000,
							}
						);
					}
				}
				//todo: show error to the user
				return throwError(() => err);
			})
		);
	}

	addToken(req: HttpRequest<any>): HttpRequest<any> {
		const urlParts = req.url.split("/");
		const requestRoute = urlParts[urlParts.length - 2];
		const utilitiesConfigsRequestRoute = urlParts[urlParts.length - 2]+"/"+urlParts[urlParts.length - 1];

		if (
			requestRoute === "meta" ||
			requestRoute === "register" ||
			requestRoute === "register-confirmation" ||
			requestRoute === "pre-check"
		) {
			return req.clone({
				headers: req.headers.set(
					"x-authorization",
					`Bearer ${environment.publicApiKey}`
				),
			});
		}
		return requestRoute == "dashboard-access" ||
			requestRoute == "request-otp" ||
			requestRoute == "otp-check" ||
			requestRoute == "change-password" ||
			requestRoute == "registerations" ||
			utilitiesConfigsRequestRoute == "utilities/configs"
			? req
			: req.clone({
					headers: req.headers
						.set("Authorization", `Bearer ${this.jwtServie.getAccessToken()}`)
						.set("org-id", `${this.authService.getOrgId()}`),
			  });
	}
	addLang(req: HttpRequest<any>): HttpRequest<any> {
		return req.clone({
			headers: req.headers.set("Accept-Language", `${this.lang}`),
		});
	}
	addOSAndVersion(req: HttpRequest<any>): HttpRequest<any> {
		const ignoredUrls = [
			"https://quicksightdashboards-wxwmlgr4rq-uc.a.run.app",
		];
		if (ignoredUrls.find((url) => req.url.includes(url))) {
			return req;
		} else
			return req.clone({
				headers: req.headers
					.set("os", "portal")
					.set("app-version", this.appVersion.version),
			});
	}
	addAppId(req: HttpRequest<any>): HttpRequest<any> {
		const requestMethod = req.method;
		const shouldAddAppId = requestMethod == "POST" || requestMethod == "PATCH";
		if (!shouldAddAppId) return req;

		const appId = req.body.app_id || undefined;

		if (!appId) return req;

		const newBody = { ...req.body };
		delete newBody.app_id;

		return req.clone({
			headers: req.headers.set("app-id", `${appId}`),
			body: newBody,
		});
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
		const urlParts = req.url.split("/");
		const requestRoute = urlParts[urlParts.length - 2];

		// if 401 error when trying to log in, it means wrong credentials. Otherwise, refresh the token
		if (requestRoute == "dashboard-access") {
			return throwError(() => ApiErros.wrongCredentials);
		} else {
			if (!this.isRefreshing) {
				this.isRefreshing = true;
				this.refreshTokenSubject.next(null);

				return this.authService.refreshToken().pipe(
					switchMap((response: any) => {
						this.isRefreshing = false;
						this.refreshTokenSubject.next(response.access);
						this.jwtServie.storeAccessToken(response);

						return next.handle(this.addToken(req));
					}),
					catchError((err) => {
						this.isRefreshing = false;
						this.authService.logOut();
						return throwError(() => err);
					})
				);
			} else {
				window.location.reload();
			}
		}

		return this.refreshTokenSubject.pipe(
			filter((token) => token !== null),
			take(1),
			switchMap((token) => next.handle(this.addToken(req)))
		);
	}
}
