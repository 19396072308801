<!-- <div *ngIf="isSideBarCollapsed" (click)="isSideBarCollapsed = false">
	<mat-icon>menu</mat-icon>
</div> -->

<div class="side-bar-container">
	<div
		class="side-bar-blur-mobile"
		[ngClass]="sideBarMobileBlurClassList"
		(click)="onSideBarClick()"
	></div>
	<div class="side-bar" [classList]="sideBarClassList">
		<div class="environment-container" *ngIf="currentEnv">
			<p>{{ currentEnv }}</p>
		</div>
		<div class="logo-name-wrapper">
			<div class="logo-name">
				<img
					src="../../../../../assets/Techrar icon.png"
					class="logo"
					alt="logo app"
					srcset=""
				/>
				<span class="logo-name__name"
					>{{ "portal" | translate }} {{ appVersion.version }}
				</span>
			</div>
			<button
				class="logo-name__button"
				[class.reversed]="lang == 'ar'"
				(click)="onSideBarClick()"
			>
				<i [classList]="arrowClassList" id="logo-name__icon"></i>
			</button>
			<!-- <i class='bx bx-arrow-from-left'></i> -->
		</div>

		<!-- could be used for new actions -->
		<!-- <div class="message">
			<i class="message-icon bx bx-message-square-edit"></i>
			<span class="message-text">New Mesage</span>
			<span class="tooltip">New Mesage</span>
		</div> -->

		<ul
			class="features-list"
			*ngIf="
				activeRoute != '/onboarding' && activeRoute != '/under-maintenance'
			"
		>
			<ng-container *ngFor="let feature of featuresList">
				<a
					[routerLinkActive]="['active']"
					*ngIf="canUserView(feature)"
					[class]="getFeatureClasses(feature)"
					[routerLink]="[feature.route]"
					[matTooltip]="feature.text | translate"
					[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
					[matTooltipDisabled]="!isSideBarCollapsed"
					class="features-item"
					[class.centered-feature]="isSideBarCollapsed"
					(click)="onSideBarLinkClick()"
				>
					<i [class]="getFeaturesCollapsedClasses(feature)"
						><span class="status"></span
					></i>
					<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
						feature.text | translate
					}}</span>
					<span *ngIf="feature.numberOfUpdates" class="inbox-number"></span>

					<!-- tooltip is not working and replaced with mat tool tip -->
					<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
				</a>
			</ng-container>

			<ng-container *ngFor="let featureFolder of featuresFoldersList">
				<div class="features-folder-container">
					<li
						(click)="toggleFolder(featureFolder)"
						*ngIf="appService.canView(featureFolder.permissionCode)"
						[class]="getFeatureClasses(featureFolder)"
						class="features-folder"
						[class.centered-feature-folder]="isSideBarCollapsed"
						[matTooltip]="featureFolder.text | translate"
						[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
						[matTooltipDisabled]="!isSideBarCollapsed"
					>
						<i [class]="getFeaturesCollapsedClasses(featureFolder)"
							><span class="status"></span
						></i>
						<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
							featureFolder.text | translate
						}}</span>
						<div class="spacer" *ngIf="!isSideBarCollapsed"></div>
						<span *ngIf="!isSideBarCollapsed">
							<i
								*ngIf="!isFolderCollapsed(featureFolder)"
								class="bx bx-chevron-up"
							></i>
							<!-- arrow up if closed -->
							<i
								*ngIf="isFolderCollapsed(featureFolder)"
								class="bx bx-chevron-down"
							></i>
						</span>
						<span *ngIf="featureFolder.numberOfUpdates" class="inbox-number">{{
							featureFolder.numberOfUpdates
						}}</span>
						<span class="tooltip">{{ featureFolder.text }}</span>
					</li>
					<div
						*ngIf="!isFolderCollapsed(featureFolder)"
						class="folder-features"
					>
						<ng-container *ngFor="let feature of featureFolder.features">
							<a
								class="folder-feature"
								[routerLinkActive]="['active']"
								[class.collapsed-sidebar-folder-feature]="isSideBarCollapsed"
								*ngIf="canUserView(feature)"
								[routerLink]="
									feature.fullRoute
										? feature.fullRoute
										: [featureFolder.route, feature.route]
								"
								(click)="onSideBarLinkClick()"
							>
								<i [class]="getFeaturesCollapsedClasses(feature)"
									><span class="status"></span
								></i>
								<div class="folder-feature-text">
									{{ (isSideBarCollapsed ? "" : feature.text) | translate }}
								</div>
							</a>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<ng-container *ngFor="let feature of belowFeaturesList">
				<a
					[routerLinkActive]="['active']"
					*ngIf="appService.canView(feature.permissionCode)"
					[class]="getFeatureClasses(feature)"
					[routerLink]="[feature.route]"
					[matTooltip]="feature.text | translate"
					[matTooltipPosition]="isEnglish() ? 'right' : 'left'"
					[matTooltipDisabled]="!isSideBarCollapsed"
					class="features-item"
					[class.centered-feature]="isSideBarCollapsed"
					(click)="onSideBarLinkClick()"
				>
					<i [class]="getFeaturesCollapsedClasses(feature)"
						><span class="status"></span
					></i>
					<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
						feature.text | translate
					}}</span>
					<span *ngIf="feature.numberOfUpdates" class="inbox-number"></span>

					<!-- tooltip is not working and replaced with mat tool tip -->
					<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
				</a>
			</ng-container>
		</ul>
		<div class="side-bar-bottom-nav">
			<a
				class="bx bxs-news updates"
				href="https://techrar.com/index.php/updates/"
				target="_blank"
				class="features-item"
				[class.centered-feature]="isSideBarCollapsed"
				style="margin-top: 1rem"
				(click)="onSideBarLinkClick()"
			>
				<i class="bx bxs-news updates features-item-icon"
					><span class="status"></span
				></i>
				<span class="features-item-text" *ngIf="!isSideBarCollapsed">{{
					"updates" | translate
				}}</span>
				<span *ngIf="true" class="inbox-number"></span>

				<!-- tooltip is not working and replaced with mat tool tip -->
				<!-- <span class="tooltip features-item-text-tooltip">{{
						feature.text
					}}</span> -->
			</a>

			<div
				*ngIf="isLoggedIn"
				class="logout-btn"
				[class.centered-logout-btn]="isSideBarCollapsed"
				(click)="logOut()"
			>
				<div class="bx bxs-log-out features-item-icon"></div>
				<span class="logout-text" *ngIf="!isSideBarCollapsed">{{
					"logout" | translate
				}}</span>
			</div>
		</div>
		<!-- <ul class="category-list">
		<div class="category-header">Message categories</div>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #79d861"
			></span
			><span class="category-item-text">My works</span
			><span class="category-item-number">9</span>
			<span class="tooltip">My works</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #c43c5d"
			></span
			><span class="category-item-text">Accountant</span
			><span class="category-item-number">43</span>
			<span class="tooltip">Accountant</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #ff5050"
			></span
			><span class="category-item-text">Works</span
			><span class="category-item-number">78</span>
			<span class="tooltip">Works</span>
		</li>
		<li class="category-item">
			<span
				class="category-item-status"
				style="background-color: #42ffdd"
			></span
			><span class="category-item-text">Marketing</span
			><span class="category-item-number">253</span>
			<span class="tooltip">Marketing</span>
		</li>
	</ul> -->

		<!-- <ul class="chat-list">
		<div class="chat-header">recent chats</div>
		<button class="chat-new-btn">
			<i class="bx bxs-plus-circle chat-icon"></i>
			<span class="chat-new-btn-text">Start New Chat</span>
			<span class="tooltip">New Chat</span>
		</button>

		<li class="chat-item">
			<span class="chat-item-avatar-wrapper has-message">
				<img
					src="../../../../../assets/yumealz_tongue.png"
					alt="avatar"
					class="chat-item-avatar"
				/>
			</span>
			<span class="chat-item-name">Steve Rogers</span>
			<span class="chat-item-number">53</span>
		</li>
		<li class="chat-item">
			<span class="chat-item-avatar-wrapper">
				<img
					src="../../../../../assets/yumealz_tongue.png"
					alt="avatar"
					class="chat-item-avatar"
				/>
			</span>
			<span class="chat-item-name">Tony Stark</span
			><span class="chat-item-status" style="background-color: #79d861"></span>
		</li>
	</ul> -->
	</div>

	<div
		[class.loggedOut]="!isLoggedIn"
		class="sidebar-content"
		[class.collapsed-sidebar-app-margin]="isSideBarCollapsed"
	>
		<div class="navbar" [class.loggedOut]="!isLoggedIn">
			<div class="start-side-container">
				<div class="nav-name-menu-icon-container">
					<button class="stack-menu__button" (click)="onSideBarClick()">
						<i class="bx bx-menu"></i>
					</button>

					<div class="name-container">
						<h6 class="greeting">{{ "welcome" | translate }}</h6>
						<h4 class="user-name">{{ getUserName() }}</h4>
						<p class="greeting">{{ dashboardUserProfile?.username }}</p>
					</div>
				</div>
				<div
					class="environment-container"
					[class.loggedOut]="!isLoggedIn"
					*ngIf="currentEnv && shouldShowTestOnTopBar"
				>
					<p>{{ currentEnv }}</p>
				</div>
			</div>

			<div class="org-container">
				<div *ngIf="dashboardUserProfile" class="org">
					<img [src]="currentOrganization?.logo" alt="" />
					<p class="org-name" (click)="toggleDropdown()">
						{{
							(lang === "en"
								? currentOrganization?.name_en ?? "-"
								: currentOrganization?.name_ar ?? "-"
							).toUpperCase()
						}}
						<mat-icon>arrow_drop_down</mat-icon>
					</p>
					<div
						class="dropdown"
						*ngIf="dropdownOpen"
						[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
					>
						<div class="wifi-container" [class.loggedOut]="!isLoggedIn">
							<i
								*ngIf="isOnline"
								class="bx bx-wifi online connectivity-status"
								(click)="toggleConnection()"
							></i>
							<p *ngIf="isOnline" (click)="toggleConnection()">
								{{ "connected" | translate }}
							</p>
							<i
								*ngIf="!isOnline"
								class="bx bx-wifi-off offline connectivity-status"
								(click)="toggleConnection()"
							></i>
							<p *ngIf="!isOnline" (click)="toggleConnection()">
								{{ "disconnected" | translate }}
							</p>
						</div>
						<div class="language-container" (click)="toggleLanguageDropdown()">
							<mat-icon>language</mat-icon>
							<div class="language-text">
								<span>{{ "languages" | translate }}</span>
								<strong>{{ currentLanguage | translate }}</strong>
							</div>
							<mat-icon class="arrow" *ngIf="appLanguage === 'en'"
								>arrow_forward_ios</mat-icon
							>
							<mat-icon class="arrow" *ngIf="appLanguage === 'ar'"
								>arrow_back_ios</mat-icon
							>
						</div>

						<div
							class="language-dropdown"
							*ngIf="languageDropdownOpen"
							[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
							[class.left]="appConfig?.url_slug == null || hideVisitYourStore"
						>
							<div class="language-option" (click)="changeLanguage('en')">
								<span>{{ "english" | translate }}</span>
							</div>
							<div class="language-option" (click)="changeLanguage('ar')">
								<span>{{ "arabic" | translate }}</span>
							</div>
						</div>
						<div
							class="org-option add-new"
							*ngIf="isLoggedIn"
							[routerLink]="['/organizations', currentOrganization?.id]"
						>
							<mat-icon>settings</mat-icon>
							<p>
								{{ "settings" | translate }}
							</p>
						</div>
						<div class="switch-organization">
							<span>{{ "switch-organization" | translate }}</span>
						</div>
						<div
							class="organization-options"
							(click)="toggleDropdownOrganization()"
						>
							<img [src]="currentOrganization?.logo" alt="" />
							<div class="organization-text">
								<span>{{ "organization" | translate }}</span>
								<strong>{{
									(lang === "en"
										? currentOrganization?.name_en ?? "-"
										: currentOrganization?.name_ar ?? "-"
									).toUpperCase()
								}}</strong>
							</div>

							<mat-icon class="arrow" *ngIf="appLanguage === 'en'"
								>arrow_forward_ios</mat-icon
							>
							<mat-icon class="arrow" *ngIf="appLanguage === 'ar'"
								>arrow_back_ios</mat-icon
							>
						</div>
						<div
							class="organization-dropdown"
							*ngIf="dropdownOpenOrganization"
							[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
							[class.left]="appConfig?.url_slug == null || hideVisitYourStore"
						>
							<div
								class="org-option"
								*ngFor="let organization of otherOrganizations"
								(click)="changeOrganization(organization.id)"
							>
								<img [src]="organization?.logo" alt="" />
								<p>
									{{
										(lang === "en"
											? organization?.name_en ?? "-"
											: organization?.name_ar ?? "-"
										).toUpperCase()
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="visit-store-btns">
					<button
						*ngIf="appConfig?.url_slug && !hideVisitYourStore"
						class="visit-store-btn"
						aria-label="Visit store website"
						[ngClass]="{
							'border-radius': restaurants && restaurants.length > 1
						}"
						[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
					>
						<div class="button-content">
							<mat-icon>visibility</mat-icon>
							<span
								*ngIf="restaurants && restaurants.length === 1"
								(click)="visitStore()"
							>
								{{ "visit" | translate }} {{ restaurants[0].name_en }}
								{{ "store" | translate }}
							</span>
							<span
								*ngIf="restaurants && restaurants.length > 1"
								(click)="visitStore()"
							>
								{{ "visit" | translate }} {{ selectedRestaurantName }}
								{{ "store" | translate }}
							</span>
						</div>
					</button>
					<button
						*ngIf="
							appConfig?.url_slug &&
							!hideVisitYourStore &&
							restaurants &&
							restaurants.length > 1
						"
						class="visit-store-btn-arrow"
						aria-label="Visit store website"
						[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
					>
						<div class="button-content">
							<div
								class="visit-store-dropdown"
								(click)="toggleVisitStoreDropdown()"
							>
								<mat-icon>keyboard_arrow_down</mat-icon>
							</div>
						</div>
					</button>
					<div
						*ngIf="showDropdown"
						class="visit-store-dropdown-menu"
						[attr.dir]="appLanguage === 'ar' ? 'rtl' : 'ltr'"
					>
						<div *ngIf="restaurants && restaurants.length > 1">
							<ul>
								<li
									*ngFor="let restaurant of restaurants"
									(click)="onRestaurantSelect(restaurant)"
								>
									<div class="restaurants-cont">
										<img [src]="restaurant?.logo" alt="" />
										<p>{{ restaurant.name_en }}</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="my-app-container">
			<!-- <app-under-maintenance-page></app-under-maintenance-page> -->
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
