<div class="pop-up-container">
	<div class="pop-up__header">
		<div class="pop-up__header__title" mat-dialog-title>
			{{ config.title | translate }}
		</div>
	</div>
	<div class="pop-up__content">
		<mat-dialog-content>
			<mat-hint *ngIf="config.hint" class="form-hint">{{
				config.hint | translate
			}}</mat-hint>
			<form [formGroup]="form" class="dialog-form">
				<ng-container *ngFor="let field of config.fields">
					<ng-container *ngIf="!field.showWhen || shouldShowField(field)">
						<!-- Text, Email, Password inputs -->
						<mat-form-field
							*ngIf="
								['text', 'email', 'password', 'number'].includes(field.type)
							"
							appearance="outline"
							class="form-field"
						>
							<mat-label>{{ field.label | translate }}</mat-label>
							<input
								[type]="field.type"
								matInput
								[disabled]="field.isDisabled || false"
								[formControlName]="field.name"
								[placeholder]="
									field.placeholder ? (field.placeholder | translate) : ''
								"
								[attr.required]="field.required || null"
								[maxlength]="getMaxLength(field)"
								[attr.pattern]="getPattern(field)"
							/>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
							<mat-error *ngIf="form.get(field.name)?.errors">{{
								getErrorMessage(field.name) | translate
							}}</mat-error>
						</mat-form-field>
						<!-- Radio buttons -->
						<ng-container *ngIf="field.type === 'radio'">
							<label class="radio-label">{{ field.label | translate }}</label>
							<mat-radio-group
								[formControlName]="field.name"
								class="radio-group"
							>
								<mat-radio-button
									*ngFor="let option of field.options"
									[value]="option?.value"
								>
									{{ option.label | translate }}
								</mat-radio-button>
							</mat-radio-group>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
						</ng-container>

						<!-- Date input -->
						<mat-form-field
							*ngIf="field.type === 'date'"
							appearance="outline"
							class="form-field"
						>
							<mat-label>{{ field.label | translate }}</mat-label>
							<input
								matInput
								[matDatepicker]="picker"
								[formControlName]="field.name"
								[attr.required]="field.required || null"
								[min]="field.datePickerAllowFrom"
								[max]="field.datePickerAllowTo"
							/>
							<mat-datepicker-toggle
								matSuffix
								[for]="picker"
							></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
							<mat-error *ngIf="form.get(field.name)?.errors">{{
								getErrorMessage(field.name) | translate
							}}</mat-error>
						</mat-form-field>

						<!-- Time input -->
						<mat-form-field
							*ngIf="field.type === 'time'"
							appearance="outline"
							class="form-field"
						>
							<mat-label>{{ field.label | translate }}</mat-label>
							<input
								matInput
								type="time"
								[formControlName]="field.name"
								[attr.required]="field.required || null"
								[step]="field.timeInterval ? field.timeInterval * 60 : 60"
							/>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
							<mat-error *ngIf="form.get(field.name)?.errors">{{
								getErrorMessage(field.name) | translate
							}}</mat-error>
						</mat-form-field>

						<!-- Textarea -->
						<mat-form-field
							*ngIf="field.type === 'textarea'"
							appearance="outline"
							class="form-field"
						>
							<mat-label>{{ field.label | translate }}</mat-label>
							<textarea
								matInput
								[disabled]="field.isDisabled || false"
								[formControlName]="field.name"
								[placeholder]="
									field.placeholder ? (field.placeholder | translate) : ''
								"
								[attr.required]="field.required || null"
								[maxlength]="getMaxLength(field)"
								[attr.pattern]="getPattern(field)"
							></textarea>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
							<mat-error *ngIf="form.get(field.name)?.errors">{{
								getErrorMessage(field.name) | translate
							}}</mat-error>
						</mat-form-field>

						<!-- Select -->
						<mat-form-field
							*ngIf="field.type === 'select'"
							appearance="outline"
							class="form-field"
						>
							<mat-label>{{ field.label | translate }}</mat-label>
							<mat-select
								[formControlName]="field.name"
								[attr.required]="field.required || null"
							>
								<mat-option
									*ngFor="let option of field.options"
									[value]="option.value"
								>
									{{ option.label | translate }}
								</mat-option>
							</mat-select>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
							<mat-error *ngIf="form.get(field.name)?.errors">{{
								getErrorMessage(field.name) | translate
							}}</mat-error>
						</mat-form-field>

						<ng-container *ngIf="field.type === 'toggle'">
							<mat-slide-toggle
								[formControlName]="field.name"
								[disabled]="field.isDisabled || false"
								[required]="field.required || false"
							>
								{{ field.label | translate }}
							</mat-slide-toggle>
							<mat-hint *ngIf="field.hint">{{
								field.hint | translate
							}}</mat-hint>
						</ng-container>
						<ng-container *ngIf="field.type === 'editor'">
							<angular-editor
								[disabled]="field.isDisabled || false"
								[formControlName]="field.name"
								[config]="editorConfig"
								[placeholder]="
									field.placeholder ? (field.placeholder | translate) : ''
								"
								[attr.required]="field.required || null"
							></angular-editor>
						</ng-container>
					</ng-container>
				</ng-container>
			</form>
		</mat-dialog-content>
		<div class="pop-up__footer-actions">
			<mat-dialog-actions align="end">
				<button mat-button (click)="onCancel()">
					{{ config.cancelButtonText || "cancel" | translate }}
				</button>
				<button
					mat-raised-button
					color="accent"
					(click)="onSubmit()"
					[disabled]="!form.valid || isSubmitting"
				>
					{{ config.submitButtonText || "save" | translate }}
				</button>
			</mat-dialog-actions>
		</div>
	</div>
</div>
